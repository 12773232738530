"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToHeading = void 0;
var slate_1 = require("slate");
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var TransformActions_1 = require("./TransformActions");
var GenericText_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/GenericText");
function convertToHeading(editor, num) {
    slate_1.Transforms.insertText(editor, " ");
    slate_1.Transforms.delete(editor, { distance: Number(num) + 1, reverse: true });
    (0, TransformActions_1.setValidBlocks)(editor, "heading".concat(num), { clearStyleOverrides: true });
}
exports.convertToHeading = convertToHeading;
function makeHeadingShortcutHash(di, num) {
    var allOtherHeadings = GenericText_1.HEADING_NUMBERS.filter(function (n) { return n !== num; }).map(function (n) { return "heading".concat(n); });
    return di
        .make(BehaviourBuilder_1.BehaviourBuilder)
        .isCalled("heading".concat(num, "ShortcutHash"))
        .isEvent(EditorTypes_1.EventType.Key)
        .withKey(BehaviourBuilder_1.KeyType.Space)
        .withModifier(BehaviourBuilder_1.KeyModifier.Any)
        .inBlockType(__spreadArray(["paragraph"], __read(allOtherHeadings), false))
        .hasTextSequence(new RegExp("^".concat("#".repeat(Number(num)), "$")))
        .doAction(function (event, editor) {
        event.preventDefault();
        convertToHeading(editor, num);
        return true;
    });
}
exports.default = (function (di) {
    return GenericText_1.HEADING_NUMBERS.map(function (n) { return makeHeadingShortcutHash(di, n); });
});
