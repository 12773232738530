"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockDisplayName = exports.getBlockIcon = void 0;
var icons_1 = require("@qwilr/kaleidoscope/icons");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var GenericBlockIcon_1 = require("./GenericBlockIcon");
function getBlockIcon(blockType) {
    var _a;
    if (!blockType)
        return null;
    return (_a = blockIconMap.get(blockType)) !== null && _a !== void 0 ? _a : null;
}
exports.getBlockIcon = getBlockIcon;
var blockIconMap = new Map([
    [BlockTypes_1.BlockType.Accept, icons_1.BlockAccept],
    [BlockTypes_1.BlockType.OldQuote, icons_1.BlockQuote],
    [BlockTypes_1.BlockType.Quote, icons_1.BlockQuote],
    [BlockTypes_1.BlockType.Source, icons_1.BlockEmbed],
    [BlockTypes_1.BlockType.Splash, icons_1.BlockSplash],
    [BlockTypes_1.BlockType.Video, icons_1.BlockVideo],
    [BlockTypes_1.BlockType.Text, GenericBlockIcon_1.GenericBlockIcon],
]);
function getBlockDisplayName(blockType) {
    var _a;
    return (_a = blockTypeDisplayNames.get(blockType)) !== null && _a !== void 0 ? _a : "";
}
exports.getBlockDisplayName = getBlockDisplayName;
var blockTypeDisplayNames = new Map([
    [BlockTypes_1.BlockType.Accept, "Accept"],
    [BlockTypes_1.BlockType.OldQuote, "Quote"],
    [BlockTypes_1.BlockType.Quote, "Quote"],
    [BlockTypes_1.BlockType.Source, "Embed"],
    [BlockTypes_1.BlockType.Splash, "Splash"],
    [BlockTypes_1.BlockType.Text, "Text"],
    [BlockTypes_1.BlockType.Video, "Video"],
]);
