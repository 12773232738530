"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var defaultBaseStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/defaultBaseStyle");
exports.default = (function (colors) {
    return __assign(__assign({}, (0, defaultBaseStyle_1.defaultBaseStyles)(commonTypes_1.BlockType.Accept, colors)), { acceptButton: {
            color: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        } });
});
