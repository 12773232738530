"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTextBlockByPreset = exports.createBaseTextBlockStyle = void 0;
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var Video_1 = require("WidgetRegistry/CommonWidgets/Video");
var IFrame_1 = require("WidgetRegistry/CommonWidgets/IFrame");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var defaultStyles_1 = require("Styles/BlockStyles/defaultStyles");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var blockConfigUtil_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/blockConfigUtil");
var createBaseTextBlockStyle = function (brandStore) {
    var primary = (0, GetBlockStyle_1.convertColorToColorObj)(brandStore.colorsWithThemeIndex[0]);
    var secondary = (0, GetBlockStyle_1.convertColorToColorObj)(brandStore.colorsWithThemeIndex[1]);
    var defaultMidnight = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_MIDNIGHT);
    var defaultWhite = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
    return {
        isMigrated: true,
        background: {
            abs: DefaultColors_1.DEFAULT_WHITE.value,
            themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
            opacity: 1,
            tintBlendMode: commonTypes_1.TintBlendModeTypes.Normal,
            card: { enabled: false, color: defaultStyles_1.defaultRgbaColor },
            type: "color",
            tintColor: defaultStyles_1.defaultRgbaColor,
        },
        name: "",
        blockType: BlockTypes_1.BlockType.Text,
        isFactoryStyle: true,
        css: "",
        blockSpacing: commonTypes_1.OptionLabelTypes.Medium,
        blockWidth: commonTypes_1.OptionLabelTypes.Medium,
        blockHeight: "none",
        h1: {
            color: primary,
            alignment: commonTypes_1.AlignmentTypes.Left,
            size: { abs: 65, themeRef: "medium" },
        },
        h2: {
            color: secondary,
            alignment: commonTypes_1.AlignmentTypes.Left,
            size: { abs: 40, themeRef: "medium" },
        },
        p: {
            color: defaultMidnight,
            alignment: commonTypes_1.AlignmentTypes.Left,
            size: { abs: 20, themeRef: "medium" },
        },
        animation: {
            animationOption: commonTypes_1.AnimationOption.None,
            animationType: commonTypes_1.AnimationType.Fade,
            direction: commonTypes_1.AnimationDirection.Down,
            speed: commonTypes_1.AnimationSpeed.Medium,
        },
        blockQuote: {
            color: defaultMidnight,
            size: {
                abs: 27,
                themeRef: "medium",
            },
            barColor: primary,
            displayQuoteIcon: false,
        },
        button: {
            textColor: (0, ColorUtils_1.shouldUseDarkText)(primary.abs) ? "dark" : "light",
            roundness: 4,
            size: BlockStyleTypes_1.ButtonWidgetSize.Medium,
            color: primary,
        },
        accordion: {
            headerBackground: {
                color: defaultWhite,
                on: true,
            },
            bodyBackground: { on: false },
            buttonAlignment: BlockStyleTypes_1.AccordionWidgetButtonAlignment.Left,
            buttonColor: primary,
            buttonStyle: BlockStyleTypes_1.AccordionWidgetButtonStyle.Arrow,
            cardStyle: BlockStyleTypes_1.AccordionWidgetCardStyle.Simple,
            imageHeight: BlockStyleTypes_1.AccordionWidgetImageHeight.Medium,
            tint: {
                color: defaultMidnight,
                opacity: 25,
            },
            expandButtonText: "Expand",
            closeButtonText: "Collapse",
        },
        link: {
            color: defaultMidnight,
            underline: {
                color: defaultMidnight,
                on: true,
            },
        },
        list: {
            color: defaultMidnight,
            marker: commonTypes_1.ListMarkerType.Circle,
            markerColor: defaultMidnight,
        },
        roiCalculator: {
            highlightColor: primary,
            textColor: "dark",
            background: {
                color: defaultWhite,
                imageBlur: 0,
            },
            tint: {
                color: defaultMidnight,
                opacity: 40,
            },
        },
        table: {
            background: {
                color: defaultWhite,
                on: false,
            },
            header: {
                color: defaultWhite,
                on: false,
            },
            border: {
                color: {
                    themeIndex: DefaultColors_1.DEFAULT_GREY.themeIndex,
                    abs: (0, ColorUtils_1.replaceOpacity)(DefaultColors_1.DEFAULT_GREY.value, 0.25),
                    opacity: 0.25,
                },
                borderType: commonTypes_1.TableBorderType.All,
            },
        },
    };
};
exports.createBaseTextBlockStyle = createBaseTextBlockStyle;
var createBaseTextBlock = function (brandStore, savedStyleService) {
    var style = (0, blockConfigUtil_1.addHash)((0, exports.createBaseTextBlockStyle)(brandStore));
    var block = {
        name: "",
        cssBlockName: "text-block",
        type: BlockTypes_1.BlockType.Text,
        displayType: BlockTypes_1.BlockType.Text,
        isMigrated: true,
        settings: {
            navigation: { exclude: false, name: "" },
            foldable: { isFolded: false, label: "Show / Hide", isFoldable: false },
            style: "",
        },
        contentAreas: {
            mainContent: {
                content: '<h1 placeholder="true">\n  <br>\n</h1>\n<p placeholder="true">\n  <br>\n</p>',
                widgets: {},
            },
        },
        style: style,
    };
    var savedStyles = savedStyleService.filterStylesByBlockType(BlockTypes_1.BlockType.Text);
    if (savedStyles && savedStyles.length > 0) {
        savedStyleService.cloneSavedStyleToBlockAngular(block, savedStyles[0]);
    }
    return block;
};
var createTextBlockByPreset = function (preset, brandStore, stockImageService, savedStyleService) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        if (preset === BlockTypes_1.BlockType.Text) {
            return [2, createBaseTextBlock(brandStore, savedStyleService)];
        }
        else if (preset === BlockTypes_1.BlockType.Splash) {
            return [2, createSplashPresetBlock(brandStore, stockImageService, savedStyleService)];
        }
        else if (preset === BlockTypes_1.BlockType.Video) {
            return [2, createVideoPresetBlock(brandStore, savedStyleService)];
        }
        else if (preset === BlockTypes_1.BlockType.Source) {
            return [2, createEmbedPresetBlock(brandStore, savedStyleService)];
        }
        else {
            throw new Error("Unsupported preset");
        }
        return [2];
    });
}); };
exports.createTextBlockByPreset = createTextBlockByPreset;
var createSplashPresetBlock = function (brandStore, stockImageService, savedStyleService) { return __awaiter(void 0, void 0, Promise, function () {
    var baseTextBlock, block, stockImage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                baseTextBlock = createBaseTextBlock(brandStore, savedStyleService);
                block = __assign(__assign({}, baseTextBlock), { displayType: BlockTypes_1.BlockType.Splash });
                return [4, stockImageService.getRandomImage({ preventBoringImages: true })];
            case 1:
                stockImage = _a.sent();
                block.style.background.type = "image";
                block.style.background.url = stockImage.url;
                block.style.blockHeight = "medium";
                block.style.p.color = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                block.style.h1.color = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                block.style.h2.color = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                block.style.blockQuote.color = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                block.style.link.color = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                block.style.link.underline.color = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                block.style.list.color = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                block.style.list.markerColor = (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE);
                return [2, block];
        }
    });
}); };
var createVideoPresetBlock = function (brandStore, savedStyleService) {
    var baseTextBlock = createBaseTextBlock(brandStore, savedStyleService);
    var block = __assign(__assign({}, baseTextBlock), { displayType: BlockTypes_1.BlockType.Video });
    var videoWidget = (0, Video_1.createVideoToken)({
        embedUrl: "",
        displayUrl: "",
        width: "wide",
    });
    block.contentAreas.mainContent = {
        content: "",
        widgets: {},
        tokens: [createEmptyHeading(), videoWidget],
    };
    return block;
};
var createEmbedPresetBlock = function (brandStore, savedStyleService) {
    var baseTextBlock = createBaseTextBlock(brandStore, savedStyleService);
    var block = __assign(__assign({}, baseTextBlock), { displayType: BlockTypes_1.BlockType.Source });
    var embedWidget = (0, IFrame_1.createEmbedToken)({ embedUrl: "", width: "wide", height: 70 });
    block.contentAreas.mainContent = {
        content: "",
        widgets: {},
        tokens: [createEmptyHeading(), __assign(__assign({}, embedWidget), { alignment: "center", embedPreset: "iframe" })],
    };
    return block;
};
var createEmptyHeading = function () {
    return [
        { subType: "heading2", type: "block" },
        { type: "inline", subType: "text", content: "" },
        { subType: "paragraph", type: "block" },
        { type: "inline", subType: "text", content: "" },
    ];
};
