"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var defaultBaseStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/defaultBaseStyle");
exports.default = (function (colors) {
    return __assign(__assign({}, (0, defaultBaseStyle_1.defaultBaseStyles)(commonTypes_1.BlockType.Splash, colors)), { h1: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                abs: DefaultColors_1.DEFAULT_WHITE.value,
            },
            size: {
                abs: 65,
                themeRef: "medium",
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        }, h2: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                abs: DefaultColors_1.DEFAULT_WHITE.value,
            },
            size: {
                abs: 40,
                themeRef: "medium",
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        }, p: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                abs: DefaultColors_1.DEFAULT_WHITE.value,
            },
            size: {
                abs: 20,
                themeRef: "medium",
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        }, blockQuote: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                abs: DefaultColors_1.DEFAULT_WHITE.value,
            },
            size: {
                abs: 27,
                themeRef: "medium",
            },
            barColor: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            displayQuoteIcon: false,
        }, background: {
            themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
            abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            opacity: 0.25,
        }, tintBlendMode: commonTypes_1.TintBlendModeTypes.Blend, backgroundCard: {
            on: false,
            color: {
                themeIndex: DefaultColors_1.DEFAULT_GREY.themeIndex,
                abs: (0, ColorUtils_1.replaceOpacity)(DefaultColors_1.DEFAULT_GREY.value, 0.25),
                opacity: 0.25,
            },
        } });
});
