"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var defaultBaseStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/defaultBaseStyle");
exports.default = (function (colors) {
    return __assign(__assign({}, (0, defaultBaseStyle_1.defaultBaseStyles)(BlockTypes_1.BlockType.Quote, colors)), { uiHighlightColor: {
            themeIndex: 0,
            abs: colors[0].value,
        }, headerSection: {
            on: true,
            color: {
                themeIndex: DefaultColors_1.DEFAULT_GREY25.themeIndex,
                abs: DefaultColors_1.DEFAULT_GREY25.value,
            },
        }, quoteBackgroundOn: true, background: {
            themeIndex: DefaultColors_1.DEFAULT_GREY10.themeIndex,
            abs: DefaultColors_1.DEFAULT_GREY10.value,
        } });
});
