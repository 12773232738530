"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListItemMenu = void 0;
var react_1 = __importStar(require("react"));
var inversify_react_1 = require("inversify-react");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var List_1 = require("@CommonFrontend/ContentTokens/Models/List");
var styles = __importStar(require("./MenuStyles.css"));
var optionsOrder = ["bullet", "number", "none"];
var config = (0, EditorWidgetRegistry_1.getConfigByType)("listItem");
var ListItemMenu = function (_a) {
    var selectedTextWidgets = _a.selectedTextWidgets, containerElementType = _a.containerElementType, onToolbarEvent = _a.onToolbarEvent;
    var editor = (0, inversify_react_1.useInjection)(EditorTypes_1.SlateEditor.$);
    var _b = __read((0, react_1.useState)(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    if (!config.validEditors.includes(editor.schema.name)) {
        return null;
    }
    if (containerElementType && !config.validContainers.includes(containerElementType)) {
        return null;
    }
    var optionsForEditor = getOptionsForEditor(editor.schema.name);
    return (react_1.default.createElement(kaleidoscope_1.ToolbarPopover, { className: tokens_1.darkTheme, size: kaleidoscope_1.PopoverSize.Auto, padding: "none", button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.ToolbarButton, __assign({}, buttonProps, { icon: react_1.default.createElement(icons_1.ListBullet, null), tooltip: { content: "List style" } }))); }, isOpen: isOpen, onChange: setIsOpen, autoStack: true },
        react_1.default.createElement(kaleidoscope_1.Stack, { padding: "xxs", gap: "xxs" }, optionsForEditor.map(function (menuOption, index) {
            var _a = optionsMapping[menuOption], icon = _a.icon, displayName = _a.displayName, eventData = _a.eventData, matchFn = _a.matchFn;
            var isInSelection = selectedTextWidgets.some(matchFn);
            return (react_1.default.createElement(kaleidoscope_1.Button, { className: styles.menuOptionButton, key: index, type: kaleidoscope_1.ButtonType.Tertiary, width: "full", onClick: function () {
                    onToolbarEvent(eventData);
                    setIsOpen(false);
                }, "aria-pressed": isInSelection },
                icon,
                displayName));
        }))));
};
exports.ListItemMenu = ListItemMenu;
var optionsMapping = {
    bullet: {
        displayName: "Bullet",
        icon: react_1.default.createElement(icons_1.ListBullet, null),
        eventData: { button: config.type, listType: List_1.ListItemType.Bullet },
        matchFn: function (_a) {
            var element = _a.element;
            return element.type === config.type && element.data.listType === List_1.ListItemType.Bullet;
        },
    },
    number: {
        displayName: "Number",
        icon: react_1.default.createElement(icons_1.ListNumber, null),
        eventData: { button: config.type, listType: List_1.ListItemType.Number },
        matchFn: function (_a) {
            var element = _a.element;
            return element.type === config.type && element.data.listType === List_1.ListItemType.Number;
        },
    },
    none: {
        displayName: "None",
        icon: react_1.default.createElement(icons_1.Menu, null),
        eventData: { button: "clearListItems" },
        matchFn: function (_a) {
            var element = _a.element;
            return element.type !== config.type;
        },
    },
};
function getOptionsForEditor(editorName) {
    if (editorName === "quotePlanCardFeatureList") {
        return optionsOrder.filter(function (o) { return o !== "number"; });
    }
    return optionsOrder;
}
