"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.videoList = void 0;
var Types_1 = require("Project/ContentSidebar/Types");
exports.videoList = [
    {
        name: "Bitsweep",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919290736-6e2a5dad727b6a9d1a9e624a045a5975b943d1de501989b9fa07ded3792b50ec-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919290736-6e2a5dad727b6a9d1a9e624a045a5975b943d1de501989b9fa07ded3792b50ec-d_1280",
            url: "https://vimeo.com/1002933115",
            duration: "00:55",
        },
    },
    {
        name: "Winter Feels",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919290868-4a41e4ac3f3251a44e76dacac270d7ab35691271440cbfdc6dec4986551f4eea-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919290868-4a41e4ac3f3251a44e76dacac270d7ab35691271440cbfdc6dec4986551f4eea-d_1280",
            url: "https://vimeo.com/1002933218",
            duration: "00:30",
        },
    },
    {
        name: "Glass ladder",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919290807-43a1074b253e8259dbebd0792df44ff43f9c6da156e8ba20fd7f1375d03ed3ec-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919290807-43a1074b253e8259dbebd0792df44ff43f9c6da156e8ba20fd7f1375d03ed3ec-d_1280",
            url: "https://vimeo.com/1002933167",
            duration: "00:27",
        },
    },
    {
        name: "Gobo",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919300783-e06d3b9b9ec98e6a012cf47a3e1556a1304b0ef63f7851ccac4d45bf8e6818bb-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919300783-e06d3b9b9ec98e6a012cf47a3e1556a1304b0ef63f7851ccac4d45bf8e6818bb-d_1280",
            url: "https://vimeo.com/1002942755",
            duration: "00:20",
        },
    },
    {
        name: "Lights",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919300729-ddf01d2889d497d192611dc53e0fe3d6022581b0b2d001e920e619905482e1b2-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919300729-ddf01d2889d497d192611dc53e0fe3d6022581b0b2d001e920e619905482e1b2-d_1280",
            url: "https://vimeo.com/1002942663",
            duration: "00:16",
        },
    },
    {
        name: "Texture",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919317649-3d08e20b612ed5885f9876e2e078fbcef4e5e6f2c19f197c5584d4dc20021e86-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919317649-3d08e20b612ed5885f9876e2e078fbcef4e5e6f2c19f197c5584d4dc20021e86-d_1280",
            url: "https://vimeo.com/1002959289",
            duration: "01:19",
        },
    },
    {
        name: "Travel",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919299969-33bf49f56eb53e3cbbcf11a65b30650c8641b6e3904fbfcc5ef1fcc66d1e5fdd-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919299969-33bf49f56eb53e3cbbcf11a65b30650c8641b6e3904fbfcc5ef1fcc66d1e5fdd-d_1280",
            url: "https://vimeo.com/1002941937",
            duration: "00:19",
        },
    },
    {
        name: "Brainstorm",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919299911-47bddb4fcca0fd72cb8f10bac8ccd64b97b3168aa000c1243c8714f01ccc420a-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919299911-47bddb4fcca0fd72cb8f10bac8ccd64b97b3168aa000c1243c8714f01ccc420a-d_1280",
            url: "https://vimeo.com/1002941902",
            duration: "00:20",
        },
    },
    {
        name: "Circuit",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919299886-a3553c8036213e05f7a02f073681a96e7f4c8a05774a34b19249581a8b427f12-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919299886-a3553c8036213e05f7a02f073681a96e7f4c8a05774a34b19249581a8b427f12-d_1280",
            url: "https://vimeo.com/1002941836",
            duration: "00:30",
        },
    },
    {
        name: "Molecule",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919299842-c45adcdc2183160691c3e7bfcc02017e67dd2921b5fc0568bf752bb79586a691-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919299842-c45adcdc2183160691c3e7bfcc02017e67dd2921b5fc0568bf752bb79586a691-d_1280",
            url: "https://vimeo.com/1002941744",
            duration: "00:14",
        },
    },
    {
        name: "Oil",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919299578-1a2a8c47ad07dadb8f35f0c0517d38af8589e635eb46588891e39e7aa01b0953-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919299578-1a2a8c47ad07dadb8f35f0c0517d38af8589e635eb46588891e39e7aa01b0953-d_1280",
            url: "https://vimeo.com/1002941588",
            duration: "00:06",
        },
    },
    {
        name: "Night",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919299528-b553827c7d78ffeebde97db5d9a7ca00b68c2cf6206ae380530c6c1d047e0b34-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919299528-b553827c7d78ffeebde97db5d9a7ca00b68c2cf6206ae380530c6c1d047e0b34-d_1280",
            url: "https://vimeo.com/1002941530",
            duration: "00:05",
        },
    },
    {
        name: "Timelapse at Night",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919299247-fe878121e8a3948223f113033c1be4e901d3c5afeb3d9839174f537300991f0d-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919299247-fe878121e8a3948223f113033c1be4e901d3c5afeb3d9839174f537300991f0d-d_1280",
            url: "https://vimeo.com/1002941291",
            duration: "00:06",
        },
    },
    {
        name: "Wood Curtains",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919298927-8439aa71ac053c99cb6c974b1a7d29cb053fb3ded3eb3e900894d009e786bd18-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919298927-8439aa71ac053c99cb6c974b1a7d29cb053fb3ded3eb3e900894d009e786bd18-d_1280",
            url: "https://vimeo.com/1002941009",
            duration: "00:12",
        },
    },
    {
        name: "Base",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919298870-468a0eeca589064e99afdeb86d90c2279fa4e3e5f34908e9d8065f1e2041e76a-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919298870-468a0eeca589064e99afdeb86d90c2279fa4e3e5f34908e9d8065f1e2041e76a-d_1280",
            url: "https://vimeo.com/1002940918",
            duration: "00:20",
        },
    },
    {
        name: "Clouds",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919298824-2aed3f3d90de7ec86690e6a4ccd46e1bc4b3cd2ed9c75e033f37417d331223a7-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919298824-2aed3f3d90de7ec86690e6a4ccd46e1bc4b3cd2ed9c75e033f37417d331223a7-d_1280",
            url: "https://vimeo.com/1002940760",
            duration: "00:15",
        },
    },
    {
        name: "City Lights",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919298354-b3220412f70f1559658110457711bd415e4eb74bc635dfa79e59a7fc5ff87fb6-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919298354-b3220412f70f1559658110457711bd415e4eb74bc635dfa79e59a7fc5ff87fb6-d_1280",
            url: "https://vimeo.com/1002940378",
            duration: "00:07",
        },
    },
    {
        name: "Browsing",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919298289-a973203c300a8f632898acc1934e2d3d9cf8ed9daca1b737ef18bea71e5720e9-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919298289-a973203c300a8f632898acc1934e2d3d9cf8ed9daca1b737ef18bea71e5720e9-d_1280",
            url: "https://vimeo.com/1002940360",
            duration: "00:11",
        },
    },
    {
        name: "Flying Evening",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919298288-ac0bc0ea6bf8498f262ccf9d9f725bb5e03784b050dda7db170ba021faa64b86-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919298288-ac0bc0ea6bf8498f262ccf9d9f725bb5e03784b050dda7db170ba021faa64b86-d_1280",
            url: "https://vimeo.com/1002940040",
            duration: "00:27",
        },
    },
    {
        name: "Flying Day",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919297957-2c100b3727e8b80d6e77d7b6ee5797253e87c2281363e353eff57dfaca748e27-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919297957-2c100b3727e8b80d6e77d7b6ee5797253e87c2281363e353eff57dfaca748e27-d_1280",
            url: "https://vimeo.com/1002939943",
            duration: "00:11",
        },
    },
    {
        name: "Coffee Shop",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919302881-9c87c2b902e057832bc0dab00d0ebd6b03dab2be6e8a914a9a0194757ff1a5d3-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919302881-9c87c2b902e057832bc0dab00d0ebd6b03dab2be6e8a914a9a0194757ff1a5d3-d_1280",
            url: "https://vimeo.com/1002944739",
            duration: "00:24",
        },
    },
    {
        name: "Students",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919310327-51f25c21381b1f4ab03d42d515bfaad3214a5a443f9529161655ffeae481ade8-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919310327-51f25c21381b1f4ab03d42d515bfaad3214a5a443f9529161655ffeae481ade8-d_1280",
            url: "https://vimeo.com/1002952047",
            duration: "00:17",
        },
    },
    {
        name: "Overview",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1919314044-781d683b1142022c7cc10c55f92e0a3b2343ae822392abe4d731d908f89cfe90-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1919314044-781d683b1142022c7cc10c55f92e0a3b2343ae822392abe4d731d908f89cfe90-d_1280",
            url: "https://vimeo.com/1002955712",
            duration: "00:18",
        },
    },
    {
        name: "Components",
        tags: [],
        type: Types_1.AssetType.Video,
        content: {
            lowResThumbnail: "https://i.vimeocdn.com/video/1921832693-1ebc344ada1fab6d7bdfe9705791227b0cc566cfea8b7c52a1fd8462361b31e0-d_295x166",
            thumbnail: "https://i.vimeocdn.com/video/1921832693-1ebc344ada1fab6d7bdfe9705791227b0cc566cfea8b7c52a1fd8462361b31e0-d_1280",
            url: "https://vimeo.com/1005260332",
            duration: "00:29",
        },
    },
];
