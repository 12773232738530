"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._mapTextFields = exports.createNewTextBlockFromTextBlock = void 0;
var MappingOldToNew_1 = require("./MappingOldToNew");
var lodash_1 = require("lodash");
var Utils_1 = require("./Utils");
var createNewTextBlockFromTextBlock = function (block, accountId, brandColors) {
    var isSavedBlock = !!block.id;
    var newBaseFieldMapping = (0, MappingOldToNew_1.mapBaseFieldsOldToNew)(block, isSavedBlock, accountId);
    var newTextMapping = (0, exports._mapTextFields)(block);
    var blockStylesMapping = undefined;
    var originalStyle = block.style;
    if (isMigratedStyle(originalStyle)) {
        var nextStyle = (0, lodash_1.cloneDeep)(originalStyle);
        blockStylesMapping = { style: nextStyle };
    }
    else {
        blockStylesMapping = { style: (0, Utils_1.oldTextStylesToNewTextStyles)(originalStyle, brandColors) };
    }
    return (0, lodash_1.merge)({}, newBaseFieldMapping, newTextMapping, blockStylesMapping);
};
exports.createNewTextBlockFromTextBlock = createNewTextBlockFromTextBlock;
var _mapTextFields = function (block) {
    return {
        settings: (0, Utils_1.mapSettings)(block.settings),
        contentAreas: block.contentAreas.mainContent !== undefined
            ? (0, lodash_1.cloneDeep)(block.contentAreas)
            : { mainContent: { tokens: [], content: "" } },
    };
};
exports._mapTextFields = _mapTextFields;
var isMigratedStyle = function (style) {
    return !!style && "isMigrated" in style;
};
