"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeColorAtRoot = exports.removeColor = exports.makeColorChange = exports.makeStrikethrough = exports.makeItalic = exports.makeBold = void 0;
var EditorTypes_1 = require("../EditorTypes");
var EditorMarks_1 = require("../Marks/EditorMarks");
var makeBold = function (editor) {
    (0, EditorMarks_1.toggleMark)({ type: EditorTypes_1.MarkType.Bold, data: {} }, editor);
};
exports.makeBold = makeBold;
var makeItalic = function (editor) {
    (0, EditorMarks_1.toggleMark)({ type: EditorTypes_1.MarkType.Italic, data: {} }, editor);
};
exports.makeItalic = makeItalic;
var makeStrikethrough = function (editor) {
    (0, EditorMarks_1.toggleMark)({ type: EditorTypes_1.MarkType.Strikethrough, data: {} }, editor);
};
exports.makeStrikethrough = makeStrikethrough;
var makeColorChange = function (editor, color) {
    (0, EditorMarks_1.replaceMark)(EditorTypes_1.MarkType.Color, { type: EditorTypes_1.MarkType.Color, data: { color: color } }, editor);
};
exports.makeColorChange = makeColorChange;
var removeColor = function (editor, match) {
    (0, EditorMarks_1.removeMark)(EditorTypes_1.MarkType.Color, editor, { match: match });
};
exports.removeColor = removeColor;
var removeColorAtRoot = function (editor, match) {
    (0, EditorMarks_1.removeMarkAtPath)(EditorTypes_1.MarkType.Color, editor, { at: EditorTypes_1.RootPath, match: match });
};
exports.removeColorAtRoot = removeColorAtRoot;
