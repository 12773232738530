import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/bIBR9z6+4ilQpXkNlO3Hruurj/sDepqmqsCEJLQEXcJp06n+fjHFicFqtm/xiLod7Lud+cPWYCPZWvpEEfk8AaqmZYVIUoCjHhu3o3QRgQ9l6YwpI4vjibvI+ueoPpUVR0pVU1B6upDBUmAKm0zvPFy615I2xvjhdmQLieu85zpzhlRGz6YkAjMJCr6TaFt0vx4b+nCELj7xIFkWBV4aqvw/kyJ07bi8yL5ABz9ISfLSbDXdXzCAXiQe6tiDCdM3xoQAmOBMUlVxWzy3uF8EGI005rdqgkWJi/dBdq1FaqgJqyYSh6gS2S1y16UJvUtCHuXPS6oWEJPR+SjndUmGmvitCV7jh5uTKwZA51PR+aujefD97sN3xbkXsNkL9unIpq7GyAgz38qPMJ9vtGRs+2s6ncCQIABOamgIqzKvZbIfVbMAQwSX4JhxF8A1Q4tcS+XpVl3KP9AYT+VpAbL+0C7ySvI3Qp43ngaGKIusFV89rJRtBkDsXSGiXjt4inB/SKGyLRT8+JiW/eXpKkiZymxRrJtZIGywIVuSE0NF8RPmfDgcpCJXuZFKEKqQwYY0OIWWoSh71eVENLiuVQlBtxx4SUlAvhXRcjWpdztJFBukyhnR546d8dR6fZLeQJktIs6WPX3+Av4YkW0CapT6cBfC8v3snyA+rhx0WSvKTnuto1CP5mR5ZtrYB27Mb5bYpEN1RYXSv0TE9aHTKq3qHis+XZVi9YQuM8rH9fOYdcXKsq6VEW7afMQEcl3OrdJ5DkreJWUSQxBfzYaf42tf/4jL91KUO3srhJDCyDvVRVh/VPTd2MrUPx2gYvUQBy9fHkCXvciaNkVu36F62/lF1z1NI/lGrdZeVNa6YOTiHPc8gWhPIrEJAMwagJA6KcBeAXgq4DiCvY0gS1vJhjLGN8/4HQ594ve8IAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var ringDataAttribute = 'data-selection-ring';
export var interactiveElementSelector = '[data-interactive-zone]';
export var textElementSelector = '[data-element-type="textElement"]';
export var childSelector = '[data-slate-node="element"]';
export var selectionRingWidth = 2;
export var outsideRingOffset = 8;
export var customRingColor = 'var(--_1nizbzd0)';
export var wrapper = _7a468({defaultClassName:'_1nizbzd1',variantClassNames:{extraSpaceBeforeFirstWidgetInBlock:{true:'_1nizbzd2'},extraSpaceAfterLastWidgetInBlock:{true:'_1nizbzd3'},widgetWidth:{'full-width':'_1nizbzd4','fit-widget':'_1nizbzd5'},isInline:{true:'_1nizbzd6',false:'_1nizbzd7'}},defaultVariants:{},compoundVariants:[]});
export var ringToWidgetOffset = 'var(--_1nizbzd9)';
export var ringToHitboxInset = 'var(--_1nizbzda)';
export var customRingBorderRadius = 'var(--_1nizbzdb)';
export var ring = _7a468({defaultClassName:'_1nizbzdd',variantClassNames:{mouseOverWidget:{true:'_1nizbzde'},mouseOverSelectableRegion:{true:'_1nizbzdf'},selected:{true:'_1nizbzdg'},mode:{hugging:'_1nizbzdh',outside:'_1nizbzdi'},nodeType:{'void':'_1nizbzdj',textElement:'_1nizbzdk',layoutElement:'_1nizbzdl'},isInline:{true:'_1nizbzdm',false:'_1nizbzdn'}},defaultVariants:{},compoundVariants:[[{mouseOverSelectableRegion:true,nodeType:'textElement'},'_1nizbzdo'],[{selected:true,nodeType:'textElement'},'_1nizbzdp']]});
export var dragHandle = _7a468({defaultClassName:'_1nizbzds',variantClassNames:{alignment:{widget:'_1nizbzdt','selection-ring':'_1nizbzdu'},mode:{hugging:'_1nizbzdv',outside:'_1nizbzdw'},nodeType:{'void':'_1nizbzdx',textElement:'_1nizbzdy',layoutElement:'_1nizbzdz'}},defaultVariants:{},compoundVariants:[]});