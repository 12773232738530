"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccordionBehaviours = void 0;
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var AccordionSelection_1 = require("Project/QwilrEditor/Widgets/AccordionWidget/AccordionSelection");
var BehaviourBuilder_1 = require("../BehaviourBuilder");
var AccordionActions_1 = require("./AccordionActions");
var TelemetryParameterValue_1 = require("Telemetry/TelemetryParameterValue");
var BehaviourName;
(function (BehaviourName) {
    BehaviourName["SelectAccordion"] = "selectAccordion";
    BehaviourName["SetAccordionExpanded"] = "setAccordionExpanded";
    BehaviourName["CloneAccordion"] = "cloneAccordion";
    BehaviourName["OnEnterWhenSelected"] = "OnEnterWhenSelected";
    BehaviourName["EnterAtEndOfAccordionHeading"] = "enterAtEndOfAccordionHeading";
    BehaviourName["QuickCreateAccordion"] = "quickCreateAccordion";
    BehaviourName["SwapAccordionImage"] = "swapAccordionImage";
    BehaviourName["EnsureVisualAccordionImage"] = "ensureVisualAccordionImage";
    BehaviourName["OnBackspaceAtStartOfAccordionHeading"] = "onBackspaceAtStartOfAccordionHeading";
    BehaviourName["OnBackspaceAfterCollapsedAccordion"] = "onBackspaceAfterCollapsedAccordion";
    BehaviourName["TabInsideAccordionHeading"] = "tabInsideAccordionHeading";
    BehaviourName["TabInsideAccordionBody"] = "tabInsideAccordionBody";
    BehaviourName["ShiftTabInsideAccordionHeading"] = "shiftTabInsideAccordionHeading";
    BehaviourName["ShiftTabInsideAccordionBody"] = "shiftTabInsideAccordionBody";
})(BehaviourName || (BehaviourName = {}));
var getAccordionBehaviours = function (di, getConversionElem, router) {
    var editorTelemetry = di.get("editorTelemetry");
    var currentBlockType = di.get("blockStore").blockType;
    var stockImagesService = di.get("stockImageService");
    var blockStyleStore = di.get("blockStore").blockStyleStore;
    var syntheticSelectionStore = di.get("syntheticSelectionStore");
    return {
        onSelectAccordion: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.SelectAccordion)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "accordion", action: EditorTypes_1.AccordionActionType.SelectAccordion })
            .doAction(function (event, editor) {
            (0, AccordionSelection_1.selectAccordion)(editor, event.data.path, syntheticSelectionStore, router, getConversionElem());
            return true;
        }),
        onSetAccordionExpanded: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.SetAccordionExpanded)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "accordion", action: EditorTypes_1.AccordionActionType.SetExpanded })
            .doAction(function (event, editor) {
            (0, AccordionActions_1.setExpanded)(editor, event.data.path, event.data.expanded);
            return true;
        }),
        onCloneAccordion: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.CloneAccordion)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "accordion", action: "clone" })
            .doAction(function (event, editor) {
            (0, AccordionActions_1.cloneAccordionWidget)(editor, event.data.path);
            editorTelemetry.accordionDuplicated();
            return true;
        }),
        onEnterAtEndOfAccordionHeading: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.EnterAtEndOfAccordionHeading)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .selectionIsCollapsed()
            .isOfAncestry("accordionHeading")
            .doAction(function (event, editor) {
            var handled = (0, AccordionActions_1.enterAtEndOfAccordionHeading)(editor);
            if (handled) {
                event.preventDefault();
            }
            return handled;
        }),
        onQuickCreateAccordion: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.QuickCreateAccordion)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .withModifier(BehaviourBuilder_1.KeyModifier.CtrlCmd)
            .isOfAncestry(["accordionHeading", "accordionBody"])
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, AccordionActions_1.quickCreateAccordion)(editor, blockStyleStore, stockImagesService);
            editorTelemetry.inlineContentAdded({
                contentType: TelemetryParameterValue_1.ContentAddedType.Accordion,
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Keyboard,
                blockType: currentBlockType,
                isInsideLayout: false,
            });
            return true;
        }),
        onSwapAccordionImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.SwapAccordionImage)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "accordion", action: EditorTypes_1.AccordionActionType.SwapImage })
            .doAction(function (event, editor) {
            var path = event.data.path;
            (0, AccordionActions_1.pickBackgroundImage)(di, editor, path);
            return true;
        }),
        onEnsureVisualAccordionImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.EnsureVisualAccordionImage)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "accordion", action: EditorTypes_1.AccordionActionType.EnsureVisualAccordionImage })
            .doAction(function (event, editor) {
            (0, AccordionActions_1.ensureVisualAccordionHasImage)(editor, event.data.path, stockImagesService);
            return true;
        }),
        onBackspaceAtStartOfAccordionHeading: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.OnBackspaceAtStartOfAccordionHeading)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .selectionIsCollapsed()
            .isOfAncestry("accordionHeading")
            .atStartOfBlock()
            .doAction(function (event, editor) {
            event.preventDefault();
            return true;
        }),
        onBackspaceAfterCollapsedAccordion: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.OnBackspaceAfterCollapsedAccordion)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .selectionIsCollapsed()
            .inBlockType(["heading1", "heading2", "paragraph"])
            .atStartOfBlock()
            .doAction(function (event, editor) {
            var handled = (0, AccordionActions_1.maybeBackspaceIntoHeading)(editor);
            if (handled) {
                event.preventDefault();
            }
            return handled;
        }),
        onTabInsideAccordionHeading: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.TabInsideAccordionHeading)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .isOfAncestry("accordionHeading")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, AccordionActions_1.moveFocusAndToggle)(editor, "accordionHeading", "forward");
            return true;
        }),
        onTabInsideAccordionBody: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.TabInsideAccordionBody)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .isOfAncestry("accordionBody")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, AccordionActions_1.moveFocusAndToggle)(editor, "accordionBody", "forward");
            return true;
        }),
        onShiftTabInsideAccordionHeading: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.ShiftTabInsideAccordionHeading)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .withModifier(BehaviourBuilder_1.KeyModifier.Shift)
            .isOfAncestry("accordionHeading")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, AccordionActions_1.moveFocusAndToggle)(editor, "accordionHeading", "backward");
            return true;
        }),
        onShiftTabInsideAccordionBody: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourName.ShiftTabInsideAccordionBody)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .withModifier(BehaviourBuilder_1.KeyModifier.Shift)
            .isOfAncestry("accordionBody")
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, AccordionActions_1.moveFocusAndToggle)(editor, "accordionBody", "backward");
            return true;
        }),
    };
};
exports.getAccordionBehaviours = getAccordionBehaviours;
