"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultBaseStyles = void 0;
var commonTypes_1 = require("./Config/BlockConfig/commonTypes");
var DefaultColors_1 = require("./Config/DefaultColors");
function defaultBaseStyles(blockType, colors, alignment) {
    if (alignment === void 0) { alignment = commonTypes_1.AlignmentTypes.Center; }
    return {
        isFactoryStyle: false,
        blockType: blockType,
        css: "",
        h1: {
            color: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            size: {
                abs: 65,
                themeRef: "medium",
            },
            alignment: alignment,
        },
        h2: {
            color: {
                themeIndex: 1,
                abs: colors[1].value,
            },
            size: {
                abs: 40,
                themeRef: "medium",
            },
            alignment: alignment,
        },
        p: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            size: {
                abs: 20,
                themeRef: "medium",
            },
            alignment: alignment,
        },
        blockQuote: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            size: {
                abs: 27,
                themeRef: "medium",
            },
            barColor: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            displayQuoteIcon: false,
        },
        background: {
            themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
            abs: DefaultColors_1.DEFAULT_WHITE.value,
        },
        textSize: {
            ref: commonTypes_1.OptionLabelTypes.Small,
            abs: 14,
        },
        blockWidth: commonTypes_1.OptionLabelTypes.Medium,
        blockSpacing: commonTypes_1.OptionLabelTypes.Medium,
        hash: "",
    };
}
exports.defaultBaseStyles = defaultBaseStyles;
