import "Application/Analytics/Page/TimeSpentAndInteractions/TimeSpentAndInteractions.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Analytics/Page/TimeSpentAndInteractions/TimeSpentAndInteractions.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VTzY7bIBC+5ynmslJzIDVtvGnJC/QtojFgh+wYLIyzrqp99wqwvey2VbU3xHw/83u4cardVMGvHYC7a9+Se2azAJyCO+9edocM4AnQG8uejQpXAY91Nczn5e+qTXcNAo5V+txYXzILfWdsloQqclrSswAO/L3N10R4Ddf1QxE9FtEKOByrh6iG46BlYB6DcZH2Gfj5L7abTJ1kAjakGeFPNwUBrZm1iqzGeaU9k44Ih1ELWF8xuNTOq2wc9BwYkumsANJtKDwes0cEBI92bJ3vBVhnkw7pELRn44DS2C7++x4pRgZUytiORTkBd/Sf2Apj8zxeLryh0+3G+W1fwn3u/3/xuSescSG4/k98STD7opxTKmfpTXCDAD7MMDoyahGRjpxnGfEqUiXXdT3qU16Z1BETjLMCGpRPnXeTVVlikVNTGqdlRUbTHjTGQWxpfctpbRLiTTLj5FuU+kdc603FlmV9T/y79sFIpHWUvVGK9IensanimzWt3u84wqEhJ59Yp632Rl4uKKW2Yak/cQ3R6lcGS5cmIZeNrKvyTuS/Q2q74+1m8yG//Ab5CYcdCgQAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var timeSpentAndInteractionsTabPanels = 'j1l5ou0';
export var tabPanelContainer = 'j1l5ou1';
export var emptyState = 'j1l5ou2';
export var tableContainer = 'j1l5ou3';
export var chartContainer = 'j1l5ou4';
export var table = 'j1l5ou5';
export var tableHeaderButton = 'j1l5ou6';
export var tableRow = _7a468({defaultClassName:'j1l5ou7',variantClassNames:{active:{true:'j1l5ou8'}},defaultVariants:{},compoundVariants:[]});
export var tableCell = 'j1l5ou9';
export var firstColumnIcon = 'j1l5oua';
export var blockNameHeading = 'j1l5oub';
export var interactionNameHeading = 'j1l5ouc';
export var timeSpentAndInteractionsGhostBlock = 'j1l5oud';