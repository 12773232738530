"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var WidgetAdderUtils_1 = require("./WidgetAdderUtils");
var Utils_1 = require("Project/QwilrEditor/Utils");
var BehaviourBuilder_1 = require("../../Behaviours/BehaviourBuilder");
var WidgetAdderStore = (function () {
    function WidgetAdderStore(usingFeature, blockStore, projectStore) {
        var _this = this;
        this.usingFeature = usingFeature;
        this.blockStore = blockStore;
        this.projectStore = projectStore;
        this.currentPath = null;
        this.moveSelectedWidgetType = function (keyType, editor) {
            var optionTypes = _this.widgetAdderOptions(editor).map(function (option) { return option.type; });
            if ([0, 1].includes(optionTypes.length)) {
                return false;
            }
            if (_this.selectedWidgetType === optionTypes[0] && keyType === BehaviourBuilder_1.KeyType.ArrowUp) {
                return false;
            }
            else if (_this.selectedWidgetType === optionTypes[optionTypes.length - 1] && keyType === BehaviourBuilder_1.KeyType.ArrowDown) {
                return false;
            }
            else if (_this.selectedWidgetType && optionTypes.includes(_this.selectedWidgetType)) {
                var inc = keyType === BehaviourBuilder_1.KeyType.ArrowUp ? -1 : 1;
                _this.selectedWidgetType = optionTypes[optionTypes.indexOf(_this.selectedWidgetType) + inc];
                return true;
            }
            else if (keyType === BehaviourBuilder_1.KeyType.ArrowDown) {
                _this.selectedWidgetType = optionTypes[0];
                return true;
            }
            else {
                return false;
            }
        };
        this.widgetAdderText = "";
        this.selectedWidgetType = null;
        this.shouldForceCloseMenu = false;
    }
    Object.defineProperty(WidgetAdderStore.prototype, "tokensAvailable", {
        get: function () {
            return this.isInTemplate || this.blockStore.isSavedBlock;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WidgetAdderStore.prototype, "isParagraphCentered", {
        get: function () {
            return this.blockStore.blockStyleStore.getElementStyle("p").alignment === "center";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WidgetAdderStore.prototype, "isInTemplate", {
        get: function () {
            return this.projectStore.isTemplate;
        },
        enumerable: false,
        configurable: true
    });
    WidgetAdderStore.prototype.widgetAdderOptions = function (editor) {
        var _this = this;
        return (0, mobx_1.computed)(function () {
            var _a, _b;
            var widgetAdderContext = {
                inLayoutType: !!editor.selection
                    ? (_b = (_a = (0, Utils_1.getClosestLayout)(editor, editor.selection.anchor.path)) === null || _a === void 0 ? void 0 : _a[0].type) !== null && _b !== void 0 ? _b : null
                    : null,
                filterText: _this.widgetAdderText,
                usingFeature: _this.usingFeature,
                tokensAvailable: _this.tokensAvailable,
            };
            return (0, WidgetAdderUtils_1.getWidgetAdderOptions)(widgetAdderContext);
        }).get();
    };
    WidgetAdderStore.prototype.widgetTypeToAdd = function (editor) {
        var _this = this;
        return (0, mobx_1.computed)(function () {
            var widgetAdderOptions = _this.widgetAdderOptions(editor).map(function (option) { return option.type; });
            if (_this.selectedWidgetType && widgetAdderOptions.includes(_this.selectedWidgetType)) {
                return _this.selectedWidgetType;
            }
            else {
                return widgetAdderOptions[0];
            }
        }).get();
    };
    var _a, _b;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], WidgetAdderStore.prototype, "widgetAdderText", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], WidgetAdderStore.prototype, "selectedWidgetType", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], WidgetAdderStore.prototype, "shouldForceCloseMenu", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], WidgetAdderStore.prototype, "currentPath", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], WidgetAdderStore.prototype, "tokensAvailable", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], WidgetAdderStore.prototype, "isParagraphCentered", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], WidgetAdderStore.prototype, "isInTemplate", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], WidgetAdderStore.prototype, "moveSelectedWidgetType", void 0);
    return WidgetAdderStore;
}());
exports.default = WidgetAdderStore;
