"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHeading1Token = exports.Heading1Config = void 0;
var Heading1_css_1 = require("ContentTokens/SharedWidgetComponents/Text/Heading1.css");
var GenericText_1 = require("./GenericText");
exports.Heading1Config = (0, GenericText_1.makeGenericTextConfig)("heading1", "h1", { vars: Heading1_css_1.vars, widget: Heading1_css_1.h1Widget, spacingShim: Heading1_css_1.h1SpacingShim }, { defaultColor: { themeIndex: 0 }, defaultFontSize: 64 });
function createHeading1Token() {
    return {
        type: "block",
        subType: "heading1",
    };
}
exports.createHeading1Token = createHeading1Token;
;
