"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnStyleChangeHandler = void 0;
var StyleableBehaviours_1 = require("../Behaviours/Styleable/StyleableBehaviours");
var WidgetManager_1 = require("../WidgetManager");
function useOnStyleChangeHandler(_a) {
    var elements = _a.elements, widgetManager = _a.widgetManager;
    var onChangeHandler = function (property, val) {
        var e_1, _a;
        var path = property.path;
        try {
            for (var elements_1 = __values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
                var element = elements_1_1.value;
                widgetManager.triggerEvent(new WidgetManager_1.WidgetEvent("styleable", {
                    action: StyleableBehaviours_1.StyleableAction.UpdateElementStyles,
                    path: widgetManager.getPath(element),
                    style: [{ pathToUpdate: path, value: val }],
                }));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return onChangeHandler;
}
exports.useOnStyleChangeHandler = useOnStyleChangeHandler;
