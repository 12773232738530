"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var faro_web_sdk_1 = require("@grafana/faro-web-sdk");
var apiKey = process.env["FARO_API_KEY"];
var collectorUrl = process.env["FARO_COLLECTOR_URL"];
if (apiKey && collectorUrl) {
    (0, faro_web_sdk_1.initializeFaro)({
        url: collectorUrl,
        instrumentations: __spreadArray([], __read((0, faro_web_sdk_1.getWebInstrumentations)()), false),
        apiKey: apiKey,
        app: {
            name: "client",
            version: "1.0.0",
        },
    });
}
