import "../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css\",\"source\":\"Ll8xOXpydnJ0MCB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xOXpydnJ0MSB7CiAgbWFyZ2luLWxlZnQ6IDUwJTsKICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoLTUwJSk7CiAgd2lkdGg6IDEwMCU7CiAgbWF4LXdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9Ci5fMTl6cnZydDYgewogIG1hcmdpbi1sZWZ0OiA1MCU7CiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogIHdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDExNDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDExNSU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDE0NDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDEzMyU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLl8xOXpydnJ0NSB7CiAgICBtYXJnaW4tbGVmdDogNTAlOwogICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogICAgd2lkdGg6IHZhcigtLXBhZ2UtY29udGVudC13aWR0aCk7CiAgfQp9\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA82UbY+aQBSFv/srbtM0gcZx0V2xxWSz/8QMcIXpzluGWVdt+O8NL66MAtZkk/aTgcOcezg811ilh6/aMGlhtgkFW7IigN8TAEFNxiSxSkewDPV+fb4XK2uVON0uJ/G1x7L2SFmhOT1EsOVYG2RUR5BwKrQX6P0UdtR4hLRn5v4UEsoTbx4E3+ABPEd98oHA3Pf9ykfTNGUyi1yDRa1RzjJJmEVRXOiP/mjaJlwVYCzhjXTwAAs3Yu14kbN9qpycEqzqBImSljKJhkgqMIJj+LhYxMHakexBYwRMciaRFOyIAy/VWFbNX9QQ1vkEk+SdpTaPoB7wrkxKYoP0NYL6h1R3KiU/6BxlEQF9s2pw2DN8j7bMFJYkOePpFUMBfGFCK2OptJ1++sSRCZz2DTgBOTSjXy8nLwJTRqFIDKIEKlPwOrWswh9679dz3NXoX46B9QAoOwbL1uBqMz5vN8a349Z+uHFXbdxBiq45GiPpiqXLaX0QjWM0ClKf/QVBNxm6RVEz4+VjPyHmKnkl1TVK6wAVnHDq8tBNN9NG/cLEktpjs2mskKNAaSOprDdjgmZYGWZoN5tC06SKVuRMTGG2YymqIRFFjGmv6E8H8rzxIUXx9kWq/vanF2wAcQpopJYVgPbBiuL1x/m6f45be8aiIxiW5Y5Stg3Ofx7NztjFOeHzOaOD0b9t9p6cTeP3nOj/EoJJ7/x/QSB80nt/OvKB/Hu67ezQ/1ytE/NvmnUOfH6x5aT8A2akKKpvCQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/bIBR9z6+4ilQpXkNlO3Hruurj/sDepqmqsCEJLQEXcJp06n+fjHFicFqtm/xiLod7Lud+cPWYCPZWvpEEfk8AaqmZYVIUoCjHhu3o3QRgQ9l6YwpI4vjibvI+ueoPpUVR0pVU1B6upDBUmAKm0zvPFy615I2xvjhdmQLieu85zpzhlRGz6YkAjMJCr6TaFt0vx4b+nCELj7xIFkWBV4aqvw/kyJ07bi8yL5ABz9ISfLSbDXdXzCAXiQe6tiDCdM3xoQAmOBMUlVxWzy3uF8EGI005rdqgkWJi/dBdq1FaqgJqyYSh6gS2S1y16UJvUtCHuXPS6oWEJPR+SjndUmGmvitCV7jh5uTKwZA51PR+aujefD97sN3xbkXsNkL9unIpq7GyAgz38qPMJ9vtGRs+2s6ncCQIABOamgIqzKvZbIfVbMAQwSX4JhxF8A1Q4tcS+XpVl3KP9AYT+VpAbL+0C7ySvI3Qp43ngaGKIusFV89rJRtBkDsXSGiXjt4inB/SKGyLRT8+JiW/eXpKkiZymxRrJtZIGywIVuSE0NF8RPmfDgcpCJXuZFKEKqQwYY0OIWWoSh71eVENLiuVQlBtxx4SUlAvhXRcjWpdztJFBukyhnR546d8dR6fZLeQJktIs6WPX3+Av4YkW0CapT6cBfC8v3snyA+rhx0WSvKTnuto1CP5mR5ZtrYB27Mb5bYpEN1RYXSv0TE9aHTKq3qHis+XZVi9YQuM8rH9fOYdcXKsq6VEW7afMQEcl3OrdJ5DkreJWUSQxBfzYaf42tf/4jL91KUO3srhJDCyDvVRVh/VPTd2MrUPx2gYvUQBy9fHkCXvciaNkVu36F62/lF1z1NI/lGrdZeVNa6YOTiHPc8gWhPIrEJAMwagJA6KcBeAXgq4DiCvY0gS1vJhjLGN8/4HQ594ve8IAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/Widgets/Layout/LayoutWidget.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/Widgets/Layout/LayoutWidget.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Ya2+jOBT93l9xd6SRoBt3gTzautJoP+6/qAw4xK2xGduk6az631fYEDDk1WlnNYrUBHx9fB/n3Gv15jFhakVeIvj3CqCSmhkmBQZFOTFsSx+u3q5uWpvE2iDUPsYYjCJCV0RRYR6ObAcgnBUCMUNLjYEJZhjhHizGKV1LRS18JoWhwmD48sWHJKmWvDYWkglNDYao+Z3KHdIbkssXDJH93FW7B4vEpcKwJSrofQ6bFet2C2utWqO8VqR5i/TjY5zy26enOK7DdpESzUSBtCEiJyrvLXToHGXCUIXolgqjMQgp6ChKsjZUfTxI+74NtQ1uVbIl00n4O4Udq5qkmUrgcJ1zpitOXs/t6HN2YIOzmk9oaYNDJdsFTAAn6QxUkQbxCuLlHJJlEkIcfZ0N2RsOARc/AZicAlxeChjPIY5uIVnEZxDvLpDrvbXZUFZsDIY4ir6OSfDx8vdoqFKyosq8YpAVyZh5ncGacUPV0CdifWoNMEQ3SYPi7DAUirzqjHAaxFF0karu4RtcH4izt0j93N+eaFrHxadyqpAiOas1hsS1F1kbzgTFEFc70JKz3Nfc7URzvyrdzpEZpCR7LpSsRY4sv96pz/S0zLJJIp3LjsouR72/kUfXfLJ3pJ+hsesP42A+LEKKN3Lb9pKfAZ+fAi9aPfrJrhsSDc02I9kOGWdk1fKN07XBsBzodS1V2RKXE0MDtGycaf5aWnTcTyJHzReWmw2GZOEe9yUtFMstKTjJKNoPoYwKK9NDeRnWWNdqTTK6L3IRjkeTs3ZPiHK6tUyPe1rkdkuv/7FE2pVPkMoPxEROdxjiYQGe/f7jraUtQbpnfsJW+oQuL+wqfY1TaYwsvYJ3HbqtXsZJWQVRtZv5Uz4OZ5ARngWB125ECAiC0X0AriEJQ/hrtLBobOMwnNTv6NUi/t+vFoNcv/vmZNPc6scTUyeUOy/TbUO/RGmHR8Ex2XTsmKRovOFTsjVp6PJ0Q6/ef+8+m9gRh7uGdElqDwu2GnTtodwm8wMgq5Vuck9fkKKa/fCC/f5hBOfD3svkeJZcZqxGm3zAn3Bf7cJx/t7T2EltJPzBykoqQ4Q3UrQbaAMRuzp3XB3eT8Z3TA9nOB5HaN7RF06Jfxq0PUnF6KycaZJymn/acd5Q6k8yvgTWnFo+Nt8oZ4pm+5ZVl6JZeaq1YevXfjh6bXAeTsk+POwbXOM1U9qgbMO4i64kqmACudY/Cq0ied6I+sDiGJaTQ6j7MXIE+PB6j11PSP129XcTO2GCKki5zJ67XEBQMoFaZUfVLrR7+38OeP7atcbNXbfDJdJDdEs2p/uW0XWQfYhOTY0CvNeqV0bz/m3oSvdjNSnIGR9LJoJetwhWi2oXzk64Hp45e1C1X3T023/R1TaYwhEAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var layoutWrapper = '_2ir6aw0';
export var layout = _7a468({defaultClassName:'_2ir6aw2',variantClassNames:{mouseOverSelectableRegion:{true:'_2ir6aw3'},selected:{true:'_2ir6aw4'},editMode:{true:'_2ir6aw5'},fullWidth:{true:'_2ir6aw6'}},defaultVariants:{},compoundVariants:[]});
export var layoutColumn = '_2ir6aw8';
export var layoutColumnContent = _7a468({defaultClassName:'_2ir6aw9',variantClassNames:{editMode:{true:'_2ir6awa'}},defaultVariants:{},compoundVariants:[]});
export var columnFrame = _7a468({defaultClassName:'_2ir6awb',variantClassNames:{mouseOverWidget:{true:'_2ir6awc'},selected:{true:'_2ir6awd'},editMode:{true:'_2ir6awe'},isResizing:{true:'_2ir6awf'}},defaultVariants:{},compoundVariants:[[{editMode:true,isResizing:false},'_2ir6awg']]});
export var columnToolbarButton = _7a468({defaultClassName:'_2ir6awh',variantClassNames:{editMode:{true:'_2ir6awi'},isResizing:{true:'_2ir6awj'},isOpen:{true:'_2ir6awk'}},defaultVariants:{},compoundVariants:[[{editMode:true,isResizing:false},'_2ir6awl']]});
export var layoutWidth = 'var(--_2ir6awn)';
export var gap = _7a468({defaultClassName:'_2ir6awo',variantClassNames:{editMode:{true:'_2ir6awp'},resizing:{true:'_2ir6awq'}},defaultVariants:{},compoundVariants:[]});
export var layoutAddButtonTooltipTarget = '_2ir6awr';
export var layoutAddColumnButton = '_2ir6aws';
export var layoutColumnWidget = '_2ir6awt';
export var layoutOptionMenu = '_2ir6awu';