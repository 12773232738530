"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dismissSideBySidePlaceholderFromDrop = exports.maybeInsertImageFromSidebar = exports.maybeDropImageWidgetWithoutDropIndicator = exports.maybeDropImageWidget = exports.maybeSetDragImageWidget = exports.maybeOpenImageGallery = exports.maybeMoveForwardsInImage = exports.maybeMoveBackwardsInImage = void 0;
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var Utils_1 = require("../Utils");
var WidgetActions_1 = require("./WidgetActions");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var IsDomElement_1 = require("Utils/IsDomElement/IsDomElement");
var DropLocationStore_1 = require("Project/Stores/DropLocationStore/DropLocationStore");
var SideBySideActions_1 = require("./SideBySide/SideBySideActions");
var Image_1 = require("../Widgets/ImageWidget/Image");
var ImageNodeFactory_1 = require("../Widgets/ImageWidget/ImageNodeFactory");
var maybeMoveBackwardsInImage = function (editor) {
    var _a = __read(getSelectedImageEntry(editor), 2), imageNode = _a[0], imagePath = _a[1];
    if (isShowingPlaceholder(imageNode)) {
        return false;
    }
    if (isInnerSelectedImage(imageNode)) {
        setSelectedEmptyInner(false, editor, imageNode, imagePath);
        return true;
    }
    else {
        return false;
    }
};
exports.maybeMoveBackwardsInImage = maybeMoveBackwardsInImage;
var maybeMoveForwardsInImage = function (editor) {
    var _a = __read(getSelectedImageEntry(editor), 2), imageNode = _a[0], imagePath = _a[1];
    if (isShowingPlaceholder(imageNode)) {
        return false;
    }
    if (isInnerSelectedImage(imageNode)) {
        setSelectedEmptyInner(false, editor, imageNode, imagePath);
        return false;
    }
    else {
        setSelectedEmptyInner(true, editor, imageNode, imagePath);
        return true;
    }
};
exports.maybeMoveForwardsInImage = maybeMoveForwardsInImage;
var maybeOpenImageGallery = function (editor, di) {
    var _a = __read(getSelectedImageEntry(editor), 2), imageNode = _a[0], imagePath = _a[1];
    if (isInnerSelectedImage(imageNode)) {
        (0, WidgetActions_1.chooseImage)(editor, di, imagePath);
        setSelectedEmptyInner(false, editor, imageNode, imagePath);
        return true;
    }
    else {
        return false;
    }
};
exports.maybeOpenImageGallery = maybeOpenImageGallery;
var maybeSetDragImageWidget = function (editor, event, dragStore) {
    if (!(0, IsDomElement_1.isDOMElement)(event.target)) {
        return;
    }
    var sourceNode = slate_react_1.ReactEditor.toSlateNode(editor, event.target);
    if (!(0, Image_1.isImageNode)(sourceNode)) {
        return;
    }
    var sourceNodePath = slate_react_1.ReactEditor.findPath(editor, sourceNode);
    slate_1.Transforms.select(editor, sourceNodePath);
    dragStore.startDrag([
        {
            sourcePath: sourceNodePath,
            sourceNode: (0, lodash_1.cloneDeep)(sourceNode),
            sourceEditorId: editor.uniqueId,
            type: DragStore_1.DragType.ImageWidgetFromEditor,
        },
    ]);
    return true;
};
exports.maybeSetDragImageWidget = maybeSetDragImageWidget;
var maybeDropImageWidget = function (dragStore, dropLocationStore, editor) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.ImageWidgetFromEditor);
    var dropLocation = dropLocationStore.dropLocation;
    if (currentDragItem && dropLocation) {
        var destinationPathRef = slate_1.Editor.pathRef(editor, dropLocation.path);
        var sourceEditorId = currentDragItem.sourceEditorId, sourcePath = currentDragItem.sourcePath;
        if (editor.uniqueId === sourceEditorId) {
            slate_1.Transforms.removeNodes(editor, { at: sourcePath, voids: true });
        }
        if (editor.uniqueId === dropLocation.editorId) {
            slate_1.Transforms.insertNodes(editor, currentDragItem.sourceNode, { at: destinationPathRef.current });
            slate_1.Transforms.select(editor, slate_1.Path.previous(destinationPathRef.current));
            (0, lodash_1.defer)(function () {
                slate_react_1.ReactEditor.focus(editor);
            });
            if (dropLocation.type === DropLocationStore_1.DropLocationType.TwoColumnPlaceholder) {
                (0, exports.dismissSideBySidePlaceholderFromDrop)(editor, destinationPathRef.current);
            }
            dragStore.stopDrag();
            dropLocationStore.clearDropLocation();
            destinationPathRef.unref();
            return true;
        }
    }
    return;
};
exports.maybeDropImageWidget = maybeDropImageWidget;
var maybeDropImageWidgetWithoutDropIndicator = function (dragStore, event, editor) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.ImageWidgetFromEditor);
    if (currentDragItem) {
        var destinationRange = slate_react_1.ReactEditor.findEventRange(editor, event);
        var destinationRangeRef = slate_1.Editor.rangeRef(editor, destinationRange);
        var sourceEditorId = currentDragItem.sourceEditorId, sourcePath = currentDragItem.sourcePath;
        if (editor.uniqueId === sourceEditorId) {
            slate_1.Transforms.removeNodes(editor, { at: sourcePath, voids: true });
        }
        slate_1.Transforms.select(editor, destinationRangeRef.current);
        slate_react_1.ReactEditor.insertData(editor, event.dataTransfer);
        dragStore.stopDrag();
        destinationRangeRef.unref();
        return true;
    }
    return;
};
exports.maybeDropImageWidgetWithoutDropIndicator = maybeDropImageWidgetWithoutDropIndicator;
var maybeInsertImageFromSidebar = function (editor, dragStore, dropLocationStore, mediaUsageReporter, blockType, editorTelemetry) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarImage);
    var dropLocation = dropLocationStore.dropLocation;
    if (currentDragItem && (dropLocation === null || dropLocation === void 0 ? void 0 : dropLocation.editorId) === editor.uniqueId) {
        var mediaData = currentDragItem.mediaData, mediaSource = currentDragItem.mediaSource, telemetryData = currentDragItem.telemetryData;
        var imageNode = (0, ImageNodeFactory_1.createImageNode)(mediaData.url, { alt: mediaData.altDescription });
        var insertionPath = dropLocation.path;
        if (dropLocation.type === DropLocationStore_1.DropLocationType.TwoColumnPlaceholder) {
            (0, exports.dismissSideBySidePlaceholderFromDrop)(editor, insertionPath);
        }
        slate_1.Transforms.insertNodes(editor, imageNode, { at: insertionPath });
        slate_1.Transforms.select(editor, insertionPath);
        (0, lodash_1.defer)(function () {
            slate_react_1.ReactEditor.focus(editor);
        });
        mediaUsageReporter.reportUsage(mediaSource, mediaData);
        dropLocationStore.clearDropLocation();
        var originWasFiltered = telemetryData.originWasFiltered, assetFolderType = telemetryData.assetFolderType;
        editorTelemetry.sidebarImageDraggedInto(blockType, (0, Utils_1.isInsideLayout)(editor), originWasFiltered, assetFolderType);
        return true;
    }
};
exports.maybeInsertImageFromSidebar = maybeInsertImageFromSidebar;
var getSelectedImageEntry = function (editor) {
    if (editor.selection == null) {
        throw new Error("Tried to get selected image when there is no selection");
    }
    if (slate_1.Range.isExpanded(editor.selection)) {
        throw new Error("Tried getting selected image when selection is expanded");
    }
    var imageEntry = (0, Utils_1.getClosestBlock)(editor, editor.selection.anchor.path);
    if (imageEntry == null) {
        throw new Error("Selection wasn't in a block");
    }
    var imageNode = imageEntry[0];
    if (!(0, Image_1.isImageNode)(imageNode)) {
        throw new Error("Selection wasn't in an image");
    }
    return [imageNode, imageEntry[1]];
};
var setSelectedEmptyInner = function (isSelected, editor, imageNode, path) {
    var updatedData = (0, lodash_1.cloneDeep)(imageNode.data);
    updatedData.selectedEmptyInner = isSelected;
    slate_1.Transforms.setNodes(editor, { data: updatedData }, { at: path });
};
var isInnerSelectedImage = function (imageNode) {
    return isShowingPlaceholder(imageNode) && !!imageNode.data.selectedEmptyInner;
};
var isShowingPlaceholder = function (imageNode) {
    return imageNode.data.src === "" && (imageNode.data.uploadId == null || imageNode.data.uploadId === "");
};
var dismissSideBySidePlaceholderFromDrop = function (editor, insertionPath) {
    var sideBySideNodeEntry = (0, Utils_1.checkAncestors)(editor, insertionPath, function (c) { return slate_1.Element.isElement(c) && c.type === "sideBySide"; });
    if (!sideBySideNodeEntry) {
        throw new Error("Tried to dismiss SBS placeholder from drop but no SBS was found");
    }
    (0, SideBySideActions_1.dismissSideBySidePlaceholder)(editor, sideBySideNodeEntry[1]);
};
exports.dismissSideBySidePlaceholderFromDrop = dismissSideBySidePlaceholderFromDrop;
