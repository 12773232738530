"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@CommonFrontend/Utils/initSentry");
require("@CommonFrontend/Utils/initFaro");
require("reflect-metadata");
require("jquery");
require("jquery-fixer/jquery.fixer");
require("lodash");
require("angular");
require("angular-sanitize");
require("angular-animate");
require("angular-resource");
require("angular-cookies");
require("angular-ui-router");
require("angular-ui-jq/modules/jq/jq");
require("ng-infinite-scroll");
require("angular-inview");
require("Utils/CreateUi");
require("angular-strap/dist/angular-strap.min");
require("angular-strap/dist/angular-strap.tpl");
require("angular-ui-codemirror");
require("ng-file-upload");
require("angular-lazy-image");
require("angular-spinkit/build/angular-spinkit");
require("angular-scroll");
require("ng-draggable");
require("ngtouch");
require("angular-elastic");
require("unsplash-js");
require("./Styles/main.less");
require.context("./Assets", true, /\.(svg|png|jpg|eot|ttf|woff|woff2|json)$/);
require("app");
