"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareStyle = void 0;
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var lodash_1 = require("lodash");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var prepareStyle = function (style, brandStore) {
    style.animation = style.animation || {};
    style.animation.animationOption = style.animation.animationOption || commonTypes_1.AnimationOption.None;
    style.animation.animationType = style.animation.animationType || commonTypes_1.AnimationType.Fade;
    style.animation.direction = style.animation.direction || commonTypes_1.AnimationDirection.Down;
    style.animation.speed = style.animation.speed || commonTypes_1.AnimationSpeed.Medium;
    if (style.blockType === commonTypes_1.BlockType.Source) {
        return style;
    }
    var primary = brandStore.colorsWithThemeIndex[0];
    if (!(style.blockQuote && style.blockQuote.color && style.blockQuote.color.abs)) {
        style.blockQuote = {
            color: (0, lodash_1.cloneDeep)(style.p.color),
            size: (0, lodash_1.cloneDeep)(style.p.size),
            barColor: {
                themeIndex: primary.themeIndex,
                abs: primary.value,
            },
            displayQuoteIcon: true,
        };
    }
    if (!(style.list && style.list.color && style.list.color.abs)) {
        style.list = {
            color: (0, lodash_1.cloneDeep)(style.p.color),
            marker: commonTypes_1.ListMarkerType.Circle,
            markerColor: (0, lodash_1.cloneDeep)(style.p.color),
        };
    }
    if (!(style.link && style.link.color && style.link.color.abs)) {
        style.link = {
            color: (0, lodash_1.cloneDeep)(style.p.color),
            underline: {
                on: true,
                color: (0, lodash_1.cloneDeep)(style.p.color),
            },
        };
    }
    if (!(style.button && style.button.color)) {
        style.button = {
            color: { abs: primary.value, themeIndex: primary.themeIndex },
            roundness: 4,
            size: BlockStyleTypes_1.ButtonWidgetSize.Medium,
            textColor: (0, ColorUtils_1.shouldUseDarkText)(primary.value) ? "dark" : "light",
        };
    }
    if (!style.roiCalculator) {
        style.roiCalculator = {
            highlightColor: { abs: primary.value, themeIndex: primary.themeIndex },
            textColor: "dark",
            background: {
                color: (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE),
                imageBlur: 0,
            },
            tint: {
                color: (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_MIDNIGHT),
                opacity: 40,
            },
        };
    }
    if (!style.roiCalculator.tint) {
        style.roiCalculator.tint = {
            color: (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_MIDNIGHT),
            opacity: 40,
        };
    }
    var accordionTextStyle;
    var accordionButtonStyle;
    var accordionVisualStyle;
    if (!style.accordion) {
        accordionTextStyle = {
            headerBackground: {
                on: true,
                color: (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_WHITE),
            },
            bodyBackground: {
                on: false,
            },
            buttonAlignment: BlockStyleTypes_1.AccordionWidgetButtonAlignment.Left,
            buttonStyle: BlockStyleTypes_1.AccordionWidgetButtonStyle.Arrow,
            buttonColor: (0, GetBlockStyle_1.convertColorToColorObj)(primary),
        };
    }
    if (!style.accordion || !style.accordion.buttonAlignment) {
        accordionButtonStyle = {
            buttonAlignment: BlockStyleTypes_1.AccordionWidgetButtonAlignment.Left,
            buttonStyle: BlockStyleTypes_1.AccordionWidgetButtonStyle.Arrow,
            buttonColor: (0, GetBlockStyle_1.convertColorToColorObj)(primary),
        };
    }
    if (!style.accordion || !style.accordion.cardStyle) {
        accordionVisualStyle = {
            cardStyle: BlockStyleTypes_1.AccordionWidgetCardStyle.Simple,
            imageHeight: BlockStyleTypes_1.AccordionWidgetImageHeight.Medium,
            tint: {
                color: (0, GetBlockStyle_1.convertColorToColorObj)(DefaultColors_1.DEFAULT_MIDNIGHT),
                opacity: 25,
            },
            expandButtonText: "Expand",
            closeButtonText: "Collapse",
        };
    }
    if (!style.table) {
        if (!!style.tableBackground && !(0, lodash_1.isEmpty)(style.tableBackground.color)) {
            style.table = {
                background: (0, lodash_1.cloneDeep)(style.tableBackground),
                header: (0, lodash_1.cloneDeep)(style.tableHeader),
                border: (0, lodash_1.cloneDeep)(style.tableBorder),
            };
            delete style.tableBackground;
            delete style.tableHeader;
            delete style.tableBorder;
        }
        else {
            style.table = {
                background: {
                    on: false,
                    color: {
                        themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                        abs: DefaultColors_1.DEFAULT_WHITE.value,
                    },
                },
                border: {
                    color: {
                        themeIndex: DefaultColors_1.DEFAULT_GREY.themeIndex,
                        abs: (0, ColorUtils_1.replaceOpacity)(DefaultColors_1.DEFAULT_GREY.value, 0.25),
                        opacity: 0.25,
                    },
                    borderType: commonTypes_1.TableBorderType.All,
                },
                header: {
                    on: false,
                    color: {
                        themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
                        abs: DefaultColors_1.DEFAULT_WHITE.value,
                    },
                },
            };
        }
    }
    style.accordion = __assign(__assign(__assign(__assign({}, style.accordion), accordionTextStyle), accordionButtonStyle), accordionVisualStyle);
    return style;
};
exports.prepareStyle = prepareStyle;
exports.default = (function (addDefaultTcdcStyle, brandStore) {
    return function (project) {
        return project.blocks.forEach(function (block) {
            if (block.type !== "Browser") {
                addDefaultTcdcStyle(block);
                (0, exports.prepareStyle)(block.style, brandStore);
            }
        });
    };
});
