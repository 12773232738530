"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentsDashboard = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./PaymentsDashboard.css"));
var PaymentsSettings_1 = require("./PaymentSettings/PaymentsSettings");
var PaymentsOnboarding_1 = require("./PaymentsOnboarding");
var UsePaymentsApi_1 = require("@CommonFrontend/Payments/UsePaymentsApi");
var isPaymentGatewayConnected = function (paymentGateway) {
    return (paymentGateway === null || paymentGateway === void 0 ? void 0 : paymentGateway.status) === "ready";
};
var PaymentsDashboard = function () {
    var _a = __read((0, react_1.useState)(false), 2), isSettingsModalOpen = _a[0], setIsSettingsModalOpen = _a[1];
    var _b = __read((0, react_1.useState)(false), 2), isOnboardingModalOpen = _b[0], setIsOnboardingModalOpen = _b[1];
    var _c = __read((0, react_1.useState)(null), 2), paymentGateway = _c[0], setPaymentGateway = _c[1];
    var getPaymentGateway = (0, UsePaymentsApi_1.usePaymentsApi)().getPaymentGateway;
    (0, react_1.useEffect)(function () {
        getPaymentGateway().then(function (paymentGateway) {
            setPaymentGateway(paymentGateway);
        });
    }, [isOnboardingModalOpen]);
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.wrapper },
        isSettingsModalOpen && (react_1.default.createElement(PaymentsSettings_1.PaymentsSettings, { paymentsEnabled: (paymentGateway === null || paymentGateway === void 0 ? void 0 : paymentGateway.status) === "ready", closeModal: function () { return setIsSettingsModalOpen(false); }, setIsOnboardingModalOpen: setIsOnboardingModalOpen })),
        react_1.default.createElement(PaymentsOnboarding_1.PaymentsOnboarding, { paymentGateway: paymentGateway, showModal: isOnboardingModalOpen, closeModal: function () { return setIsOnboardingModalOpen(false); } }),
        react_1.default.createElement("div", { className: styles.content },
            react_1.default.createElement(kaleidoscope_1.Heading, { level: "2", strong: true }, "Payments"),
            react_1.default.createElement(kaleidoscope_1.Stack, { justify: "spaceBetween", direction: "horizontal" },
                react_1.default.createElement(kaleidoscope_1.Text, null,
                    "Create and manage payments within Qwilr. ",
                    react_1.default.createElement(kaleidoscope_1.Link, { target: "_blank" }, "Learn more about Payments in Qwilr.")),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s" },
                    react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () { return setIsSettingsModalOpen(true); }, type: kaleidoscope_1.ButtonType.Secondary }, "Settings"),
                    react_1.default.createElement(kaleidoscope_1.Tooltip, __assign({ content: react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "xs" },
                            react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "s" }, "QwilrPay is connected"),
                            react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" }, "Disconnect your QwilrPay account to connect a new payment gateway")) }, (!isPaymentGatewayConnected(paymentGateway) ? { show: false } : {}), { wrapTarget: true, size: kaleidoscope_1.TooltipSize.Large, position: kaleidoscope_1.TooltipPosition.Bottom }),
                        react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () { return setIsOnboardingModalOpen(true); }, type: kaleidoscope_1.ButtonType.Primary, disabled: isPaymentGatewayConnected(paymentGateway) }, "Connect payment gateway")))),
            react_1.default.createElement(kaleidoscope_1.Stack, null, "Payments content placeholder"))));
};
exports.PaymentsDashboard = PaymentsDashboard;
