import "Application/DreamCoat/SavedStyle/StyleLibraryV2/StyleLibrary.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/DreamCoat/SavedStyle/StyleLibraryV2/StyleLibrary.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71XYW/rJhT93l9xValS8hQ620najqdN2l/Y12mqML52aAnwMEndTvvvE8SxsZO67XvrUxU1wAUOh3MPN9f3abL/xm+fE/jnAuBJFG5DYblamebrxb8X18fhNAxvWUM2KKqNo7DMEh8DoPdoS6mfKLCd04NZWZhViNpI9kyhsqLwM/x/4nBrJHNIuJa7raopWDTI3CxbQFraeQhkhsKe2RkhtWFcqIrU9/dpLm8fHtJUhpiHXe1E+Uy4Vg6Vo8BRObRDHJSVDm1A08VdXvrpx+MMd5H9Lmo+WGoZFjG6Fk5o5UFL5sQe/VqsNsgdscwJTSG9gV8gTb72tKZJcuWbzjJ1nJ/rhtQbVuinFkKxC/MV2fYY9vN2EFkdSHBMFcwWfUQduOiYLiU2k+QAMCkqRYTDbR13+0gmFFring1SqMULDghYBQJ62BSS8Jea5nAwwywqt2i7M9O02LmW2gYkVss/uN+yx78O+HPGHyurd6qg750UI6Mbr8VPwxdttaY0x1JbPCep7hZyqfljLLI0O6TMUQ9tM9e2QEssK8Su7mUSn6GVZ2gRlLgPGkl7gMUkgfXOloxjF16F6F7FLK+13LmgYqcNBbI+QJNYur71QoQqsKGQDti4CSxonzvumULy+tpC1ejaiFFSxNClUMgsqTwjqNzscIitaGZCgWT5wlb5LLuDZQrrdA7J1SK62fnijfC7Ufz51BnkB6pgXIYVhVDV2C2aJnKlh/l4NBp8DHs5bFx3s8QL039WpgEPNL2DLIHlISN6c92IokA1to+W9B/2jvg2ozTq7nR44bcTJngO8f/gS61aOJN85iUDxKfuPM6uc2NbZiuhqCf4TKbdjl65u3Cudz0JHaI3okY7rk/F/m53nEjXcPSJhxK+AEnDIjbi6l0TAjAalJmt19B+5hN28OuAxWw18Lxj82OHGeZQD+5gTx+YMHGaj3nnd5jz0CBj8jwdwiud4B6VqykorfAn5DobJfLJXSQ9y7Fpr5OEf3uKky/0bDrIpbZbCjVnEmfJdTYfDBBtRchKp01YvCNUvAR7bZMm183AdZMufYZJtbw5aOqk0DSScTz1klOLiijJ40L4+Di9onQ+QV9EWs/kaNmuLgjtaOEiBtGe7hjcNr/LQl6pND5ZZfiWys5XG5GQwlf/a2G2Tq4WXgZTBlS+td85wxi94e+wmNdmnMXta70FkFX7LnXIs1Nn+OTbWPYF8zVntiBEemG137kU/JHlEn9eMb1st+ofkAXEg6ye/cWsYAQbw1SBxW+Xzu7w8u85jN6cuFw59VShhBNMTu9dfnzvcqpUyuHLqaFE4xX8DoXYnzOdkyLJKw9Jju4JR6aVwLXRxp/k/t4whZLU3Gop2+sbJP3hgg6df4a+kycu3fgb+g8BayIrHBAAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var panel = '_10vqc7y0';
export var scrollContainer = '_10vqc7y1';
export var cardsContainer = '_10vqc7y2';
export var card = _7a468({defaultClassName:'_10vqc7y3',variantClassNames:{selected:{true:'_10vqc7y4'},stylesChangedFromLibrary:{true:'_10vqc7y5'}},defaultVariants:{},compoundVariants:[]});
export var cardOverlay = '_10vqc7y6';
export var styleItem = '_10vqc7y7';
export var blueTickIcon = '_10vqc7y8';
export var optionsMenuButton = '_10vqc7y9';
export var stylePreview = '_10vqc7ya';
export var stylePreviewTextCard = '_10vqc7yb';
export var stylePreviewTint = '_10vqc7yc';
export var indicator = '_10vqc7yd';
export var cardIndicator = '_10vqc7ye';
export var buttonIndicator = '_10vqc7yf';
export var customizeStyleButton = '_10vqc7yg';