"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
exports.default = (function () {
    return {
        blockType: commonTypes_1.BlockType.Source,
        isFactoryStyle: false,
        css: "",
        background: {
            themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
            abs: DefaultColors_1.DEFAULT_WHITE.value,
        },
        blockWidth: commonTypes_1.OptionLabelTypes.Medium,
        blockSpacing: commonTypes_1.OptionLabelTypes.Medium,
        hash: "",
    };
});
