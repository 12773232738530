import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/bIBR9z6+4ilQpXkNlO3Hruurj/sDepqmqsCEJLQEXcJp06n+fjHFicFqtm/xiLod7Lud+cPWYCPZWvpEEfk8AaqmZYVIUoCjHhu3o3QRgQ9l6YwpI4vjibvI+ueoPpUVR0pVU1B6upDBUmAKm0zvPFy615I2xvjhdmQLieu85zpzhlRGz6YkAjMJCr6TaFt0vx4b+nCELj7xIFkWBV4aqvw/kyJ07bi8yL5ABz9ISfLSbDXdXzCAXiQe6tiDCdM3xoQAmOBMUlVxWzy3uF8EGI005rdqgkWJi/dBdq1FaqgJqyYSh6gS2S1y16UJvUtCHuXPS6oWEJPR+SjndUmGmvitCV7jh5uTKwZA51PR+aujefD97sN3xbkXsNkL9unIpq7GyAgz38qPMJ9vtGRs+2s6ncCQIABOamgIqzKvZbIfVbMAQwSX4JhxF8A1Q4tcS+XpVl3KP9AYT+VpAbL+0C7ySvI3Qp43ngaGKIusFV89rJRtBkDsXSGiXjt4inB/SKGyLRT8+JiW/eXpKkiZymxRrJtZIGywIVuSE0NF8RPmfDgcpCJXuZFKEKqQwYY0OIWWoSh71eVENLiuVQlBtxx4SUlAvhXRcjWpdztJFBukyhnR546d8dR6fZLeQJktIs6WPX3+Av4YkW0CapT6cBfC8v3snyA+rhx0WSvKTnuto1CP5mR5ZtrYB27Mb5bYpEN1RYXSv0TE9aHTKq3qHis+XZVi9YQuM8rH9fOYdcXKsq6VEW7afMQEcl3OrdJ5DkreJWUSQxBfzYaf42tf/4jL91KUO3srhJDCyDvVRVh/VPTd2MrUPx2gYvUQBy9fHkCXvciaNkVu36F62/lF1z1NI/lGrdZeVNa6YOTiHPc8gWhPIrEJAMwagJA6KcBeAXgq4DiCvY0gS1vJhjLGN8/4HQ594ve8IAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWTXObMBC9+1doMpMZM7VcRIrtKKfeeu6tJ4+QZFCiSIwkbMed/vcOAgLyV9qQmdyQ9oO3b98uzNeILUv1tErA7wkApbbCCa0wIJnVsnL8YQLATjBXYLAlZgphFxBHtcnpEoO4fsq0c/q5OxD6lBtdKYaBybMpWgCU3oEkTcBXEM+T1AcfoFCM7zFAD/7dQjluIN9y5SwGSiveJDaMG2gIE5XtUDSXP/0dtJXZEMrXa5TJ5eMjQoVPr0tChXtpITlDVFdca2lzscqQ+h7KPsMuao2cWKFyaB1RjBjWe9hoNihzZKqHyZ/JfI1MRTJqEjAPu8KELSV56Sjxro39m7f3hc5XgXnhzW80I42CmKWPkXzjPHEDy8pbjMiLE9N9GHTBixx7+Z5stHnGzaMkjt+xaSg0FM1APANxCDP7wFzM5/oHgdi+Z9V/CgRSLbUZmfBU1VcHFq3KfX0seNON7nw0USiOb+trWhmrDe4G8dpMvk5uEhKJM77Rhns+qVaOK4fBzc1lpH5/3DWwmma2h66E5KiE5GoJx3y3WiiJ4cpdGjL2xpBtwiE7u6yEEk4QGcbhIR2n0HaFcIMNhwEq98BqKbpt4v1gzaPR8julXLkfestNL4lF1MTvoS0I07tuPTYnyCXfeqGhPoSF2s/fWVw+trg1UuKQHVg8u1xtjzqNRldaeKT9LqAYUCLpdFnuwZcjUPdRGCtOY+GyVuLA53GwkXzio91Do5PXkCgCsFZ8/y1NGyGf22a/pjCNb6PhqPIdNNyKQyjYp+Gi/lwoslmtdbp3ARlBZwdM7Sw/B+15JLQx/L5i4/YsbaqZqvaX6tOYu4BOj0f3IeQFjf0LxEV8nsoKAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var snapAreaWidth = 'var(--_1d7pnk80)';
export var snapAreaXOffset = 'var(--_1d7pnk81)';
export var resizeableSnapArea = _7a468({defaultClassName:'_1d7pnk82',variantClassNames:{visible:{false:'_1d7pnk83',true:'_1d7pnk84'},highlighted:{false:'_1d7pnk85',true:'_1d7pnk86'}},defaultVariants:{},compoundVariants:[]});
export var resizeableSnapAreaContentStart = '_1d7pnk87';
export var resizeableSnapAreaContentEnd = '_1d7pnk88';
export var resizableSnapAreaContent = '_1d7pnk89';
export var snapAreaWide = '_1d7pnk8a';
export var snapAreaFull = '_1d7pnk8b';
export var resizeHandle = _7a468({defaultClassName:'_1d7pnk8d',variantClassNames:{state:{idle:'_1d7pnk8e',hover:'_1d7pnk8f',active:'_1d7pnk8g'},inset:{true:'_1d7pnk8h',false:'_1d7pnk8i'},position:{left:'_1d7pnk8j',right:'_1d7pnk8k',topLeft:'_1d7pnk8l',topRight:'_1d7pnk8m',bottomLeft:'_1d7pnk8n',bottomRight:'_1d7pnk8o'}},defaultVariants:{},compoundVariants:[]});