"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var ShortcutActions = __importStar(require("./ShortcutActions"));
var slate_1 = require("slate");
var TelemetryParameterValue_1 = require("@CommonFrontend/Telemetry/TelemetryParameterValue");
var Utils_1 = require("../Utils");
exports.default = (function (di) {
    var telemetry = di.get("editorTelemetry");
    var blockStore = di.get("blockStore");
    return {
        addHorizontalRule: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("shortcutHorizontalRule")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Dash)
            .inBlockType("paragraph")
            .notInTable()
            .hasBlockContent(/^--$/)
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.insertText(editor, "-");
            ShortcutActions.insertHorizontalRule(editor);
            telemetry.inlineContentAdded({
                blockType: blockStore.blockType,
                contentType: TelemetryParameterValue_1.ContentAddedType.HorizontalRule,
                isInsideLayout: (0, Utils_1.isInsideLayout)(editor, editor.selection),
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Keyboard,
            });
            return true;
        }),
    };
});
