"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var defaultStyles_1 = require("Styles/BlockStyles/defaultStyles");
var defaultBaseStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/defaultBaseStyle");
exports.default = (function (colors) {
    return __assign(__assign({}, (0, defaultBaseStyle_1.defaultBaseStyles)(BlockTypes_1.BlockType.Text, colors, commonTypes_1.AlignmentTypes.Left)), { blockQuote: {
            color: {
                themeIndex: -3,
                abs: colors[colors.length - 3].value,
            },
            size: {
                abs: 27,
                themeRef: "medium",
            },
            barColor: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            displayQuoteIcon: false,
        }, background: {
            type: "color",
            themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
            abs: DefaultColors_1.DEFAULT_WHITE.value,
            opacity: 1,
            tintBlendMode: commonTypes_1.TintBlendModeTypes.Normal,
            tintColor: defaultStyles_1.defaultRgbaColor,
            card: { enabled: false, color: defaultStyles_1.defaultRgbaColor },
        }, blockHeight: "none", textSize: {
            ref: commonTypes_1.OptionLabelTypes.Small,
            abs: 14,
        } });
});
