"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTextSerialisedVariable = exports.serialiseVariableAsText = void 0;
var Types_1 = require("@CommonFrontend/Tokens/Types");
var serialiseVariableAsText = function (variable) {
    return JSON.stringify(variable);
};
exports.serialiseVariableAsText = serialiseVariableAsText;
var isTextSerialisedVariable = function (text) {
    var data;
    try {
        data = JSON.parse(text);
    }
    catch (e) {
        data = null;
    }
    if ((0, Types_1.isToken)(data)) {
        return {
            isValid: true,
            variable: data,
        };
    }
    return {
        isValid: false,
    };
};
exports.isTextSerialisedVariable = isTextSerialisedVariable;
