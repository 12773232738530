import "Application/Library/PaymentsDashboard/PaymentSettings/PaymentsSettings.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/PaymentsDashboard/PaymentSettings/PaymentsSettings.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8WUy26jMBSG93mKs6lUFkY2lwToW/QFIgOGODU2sk1DZzTvPsKE4LRVmkWkLo/Phe8/F8I9yftRvWH4uwE48doeCiAYP71sAA6Mtwe72P824RIcdqqmwqV0dERLWpLgfpwSe1rXXLYFnG3LRouo4K0sQLDG+sXIz1/2Cr5T/YyQ6WnFZYtGsd+TUuyOR0JUMAWWStdMI31O70cwSvDac1VKKL0UcgaaPWspHPiA0UMB1TvTjVCnAuhgleOi1Vur1SDrb9ku3kulP1d48S283fbTQK7xPDrp6FrNHcXQyQJMTyVEXutKZa3qHtTWxHHfB7ZmpfcMo+amF/SjcHIusizrekEtO+szBeAwbjTgcNfor1FanVwIThsNZIpYKbaO4utW370Ha6ndWspqKk2jdFeAVJL5UZkvO4nmoS6y43y2l92nNR/MQjA/vro3VClptfJYWu9ovLmCbsvnLAOSJRClcfD9mk5BUUwgSlKI0jS4an0jmINy/UHcss4UUDFpmev1cTCWNx+OiEm7ulbN+S9oJnkGJN9ClOS3RCeTaAxRmjxaNJ1/qlyiRWnqlK8RpX83l5O8XjezqhXBrQP++V7/A7vHZbwhBgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var paymentsModal = '_19pxok0';
export var leftPanelWrapper = '_19pxok1';
export var rightPanelWrapper = '_19pxok2';
export var modalHeader = '_19pxok3';
export var modalContainer = '_19pxok4';
export var modalContent = '_19pxok5';
export var settingsCard = '_19pxok6';
export var toggleStyle = '_19pxok7';
export var generalSettingsIconStyle = '_19pxok8';
export var permissionsIconStyle = '_19pxok9';
export var settingsRow = _7a468({defaultClassName:'_19pxoka',variantClassNames:{hasDivider:{true:'_19pxokb'}},defaultVariants:{},compoundVariants:[]});