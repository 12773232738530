"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceTokenWidgetWithVariable = exports.removeTokenWidget = exports.addTokenWidget = exports.getVariableTokenFromDragEvent = exports.deleteVariableFromToolbar = exports.toggleStrikethroughVariable = exports.toggleItalicVariable = exports.toggleBoldVariable = exports.dropCopyVariable = exports.deleteVariableOnDelete = exports.deleteVariableOnBackspace = exports.VariableStyleKey = void 0;
var slate_react_1 = require("slate-react");
var slate_1 = require("slate");
var EditorTypes_1 = require("../EditorTypes");
var DataTransferTypes_1 = require("CommonUI/DragDrop/DataTransferTypes");
var Utils_1 = require("../Utils");
var TokenWidget_1 = require("ContentTokens/DataModels/TokenWidget");
var Types_1 = require("Tokens/Types");
var Variable_1 = require("../WidgetRegistry/Widgets/Variable");
var VariableStyleKey;
(function (VariableStyleKey) {
    VariableStyleKey["IsBold"] = "isBold";
    VariableStyleKey["IsItalic"] = "isItalic";
    VariableStyleKey["IsStrikethrough"] = "isStrikethrough";
})(VariableStyleKey || (exports.VariableStyleKey = VariableStyleKey = {}));
var deleteVariableOnBackspace = function (editor) {
    if (!editor.selection) {
        return false;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var variableNodeEntry = (0, Utils_1.getPrevious)(editor, startPoint.path, function (curr) {
        return slate_1.Element.isElement(curr) && curr.type === EditorTypes_1.InlineType.Variable;
    });
    if (!variableNodeEntry) {
        return false;
    }
    slate_1.Transforms.removeNodes(editor, { at: variableNodeEntry[1] });
    return true;
};
exports.deleteVariableOnBackspace = deleteVariableOnBackspace;
var deleteVariableOnDelete = function (editor) {
    if (!editor.selection) {
        return false;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var variableNodeEntry = (0, Utils_1.getNext)(editor, startPoint.path, function (curr) {
        return slate_1.Element.isElement(curr) && curr.type === EditorTypes_1.InlineType.Variable;
    });
    if (!variableNodeEntry) {
        return false;
    }
    slate_1.Transforms.removeNodes(editor, { at: variableNodeEntry[1] });
    return true;
};
exports.deleteVariableOnDelete = deleteVariableOnDelete;
var dropCopyVariable = function (editor, event) {
    var variable = (0, exports.getVariableTokenFromDragEvent)(event);
    if (!variable) {
        return false;
    }
    var variableNode = (0, Variable_1.createVariableNode)(variable);
    var range = slate_react_1.ReactEditor.findEventRange(editor, event);
    slate_1.Transforms.insertNodes(editor, variableNode, { at: range });
    slate_1.Transforms.move(editor, { distance: variable.name.length - 1 });
    return true;
};
exports.dropCopyVariable = dropCopyVariable;
var toggleBoldVariable = function (editor, path) {
    updateNodeByKey(editor, path, VariableStyleKey.IsBold);
    return true;
};
exports.toggleBoldVariable = toggleBoldVariable;
var toggleItalicVariable = function (editor, path) {
    updateNodeByKey(editor, path, VariableStyleKey.IsItalic);
    return true;
};
exports.toggleItalicVariable = toggleItalicVariable;
var toggleStrikethroughVariable = function (editor, path) {
    updateNodeByKey(editor, path, VariableStyleKey.IsStrikethrough);
    return true;
};
exports.toggleStrikethroughVariable = toggleStrikethroughVariable;
var updateNodeByKey = function (editor, path, varialeStyleFlag) {
    var _a;
    var variableNodeEntry = slate_1.Editor.node(editor, path);
    var existingData = variableNodeEntry[0].data;
    var newData = (_a = {},
        _a[varialeStyleFlag] = !existingData[varialeStyleFlag],
        _a);
    var updatedData = Object.assign({}, existingData, newData);
    slate_1.Transforms.setNodes(editor, { data: updatedData }, { at: variableNodeEntry[1] });
};
var deleteVariableFromToolbar = function (editor, path) {
    var nextTextEntry = (0, Utils_1.getNext)(editor, path, slate_1.Text.isText);
    if (nextTextEntry) {
        slate_1.Transforms.select(editor, slate_1.Editor.start(editor, nextTextEntry[1]));
        slate_react_1.ReactEditor.focus(editor);
    }
    slate_1.Transforms.removeNodes(editor, { at: path });
};
exports.deleteVariableFromToolbar = deleteVariableFromToolbar;
var getVariableTokenFromDragEvent = function (event) {
    var token;
    try {
        var data = event.dataTransfer.getData(DataTransferTypes_1.VariableDataTransferFormat.Json);
        token = JSON.parse(data);
    }
    catch (e) {
        token = null;
    }
    if (!(0, Types_1.isToken)(token)) {
        return null;
    }
    return token;
};
exports.getVariableTokenFromDragEvent = getVariableTokenFromDragEvent;
var addTokenWidget = function (editor) {
    if (!editor.selection) {
        return false;
    }
    slate_1.Transforms.insertText(editor, " ");
    slate_1.Transforms.delete(editor, { distance: 2, reverse: true });
    slate_1.Transforms.insertNodes(editor, TokenWidget_1.tokenWidget.createSlateJson(), { at: editor.selection.focus });
    slate_react_1.ReactEditor.blur(editor);
    return true;
};
exports.addTokenWidget = addTokenWidget;
var removeTokenWidget = function (editor, path) {
    slate_1.Transforms.removeNodes(editor, { at: path });
    slate_react_1.ReactEditor.focus(editor);
};
exports.removeTokenWidget = removeTokenWidget;
var replaceTokenWidgetWithVariable = function (editor, path, variableToken) {
    slate_1.Transforms.setNodes(editor, (0, Variable_1.createVariableNode)(variableToken), {
        at: path,
    });
    slate_react_1.ReactEditor.focus(editor);
};
exports.replaceTokenWidgetWithVariable = replaceTokenWidgetWithVariable;
