(function () {
  module.exports = [
    {
      name: "Standard",
      className: "qwilr-standard",
      blockType: "Text",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Standard",
      className: "qwilr-standard",
      blockType: "Quote",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Standard",
      className: "qwilr-standard",
      blockType: "Splash",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Standard - Centered",
      className: "qwilr-standard-centered",
      blockType: "Text",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Impact - Centered",
      className: "qwilr-impact",
      blockType: "Text",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Impact - Left Aligned",
      className: "qwilr-impact-left",
      blockType: "Text",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Impact II - Centered",
      className: "qwilr-impact-ii",
      blockType: "Text",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Impact II - Left Aligned",
      className: "qwilr-impact-ii-left",
      blockType: "Text",
      author: "Qwilr",
      isFactoryStyle: true,
    },
    {
      name: "Monochrome",
      className: "qwilr-monochrome",
      blockType: "Text",
      author: "Qwilr",
      isFactoryStyle: true,
    },
  ];
}.call(this));
