"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createParagraphToken = exports.ParagraphConfig = void 0;
var Paragraph_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/Paragraph.css");
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var GenericText_1 = require("./GenericText");
exports.ParagraphConfig = (0, GenericText_1.makeGenericTextConfig)("paragraph", "p", { vars: Paragraph_css_1.vars, widget: Paragraph_css_1.paragraphWidget, spacingShim: Paragraph_css_1.paragraphSpacingShim }, { defaultColor: DefaultColors_1.DEFAULT_MIDNIGHT, defaultFontSize: 20 });
function createParagraphToken() {
    return {
        type: "block",
        subType: "paragraph",
    };
}
exports.createParagraphToken = createParagraphToken;
;
