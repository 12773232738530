"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileSettings = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var react_1 = __importStar(require("react"));
var profile_css_1 = require("Settings/Profile/profile.css");
var dynamic_1 = require("@vanilla-extract/dynamic");
var useProfileInformation_1 = require("Settings/Profile/useProfileInformation");
var useActiveApps_1 = require("Settings/Profile/useActiveApps");
var inversify_react_1 = require("inversify-react");
var ProfileSettings = function () {
    var _a;
    var _b = (0, useProfileInformation_1.useProfileInformation)(), data = _b.data, updateEmail = _b.updateEmail, updateName = _b.updateName, updatePassword = _b.updatePassword;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", paddingBottom: "xxxl" },
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", align: "center", paddingBottom: "xxl" },
            react_1.default.createElement("div", { style: (0, dynamic_1.assignInlineVars)((_a = {},
                    _a[profile_css_1.vars.iconBackgroundColor] = tokens_1.palette.green200,
                    _a[profile_css_1.vars.iconColor] = tokens_1.tokens.color.iconSuccess,
                    _a)), className: profile_css_1.iconWrapper },
                react_1.default.createElement(icons_1.Contact, { size: icons_1.IconSize.Medium, className: profile_css_1.iconStyle })),
            react_1.default.createElement(kaleidoscope_1.Heading, { strong: true, level: "4", element: "h1" }, "PROFILE"),
            react_1.default.createElement(kaleidoscope_1.Text, { size: "m" }, "Your personal profile and login details.")),
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xxl", align: "center" },
            react_1.default.createElement(NameSection, { name: data.name, updater: updateName }),
            react_1.default.createElement(EmailSection, { email: data.email, updater: updateEmail }),
            react_1.default.createElement(PasswordSection, { updater: updatePassword }))));
};
exports.ProfileSettings = ProfileSettings;
var NameSection = function (_a) {
    var _b;
    var name = _a.name, updater = _a.updater;
    var _c = __read((0, react_1.useState)(false), 2), hasInputChanged = _c[0], setHasInputChanged = _c[1];
    return (react_1.default.createElement(kaleidoscope_1.Card, { className: profile_css_1.cardStyle },
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "m", padding: "l" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center" },
                react_1.default.createElement("div", { style: (0, dynamic_1.assignInlineVars)((_b = {},
                        _b[profile_css_1.vars.iconBackgroundColor] = tokens_1.palette.blue100,
                        _b[profile_css_1.vars.iconColor] = tokens_1.tokens.color.controlAccent,
                        _b)), className: profile_css_1.iconWrapper },
                    react_1.default.createElement(icons_1.User, { size: icons_1.IconSize.Medium, className: profile_css_1.iconStyle })),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", align: "start", padding: "s" },
                    react_1.default.createElement(kaleidoscope_1.Label, { size: "l" }, "Name"),
                    react_1.default.createElement(kaleidoscope_1.Label, { size: "s", secondary: true }, "Set your name here"))),
            react_1.default.createElement(kaleidoscope_1.FormComponent, { onSubmit: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
                    var e_1;
                    var name = _b.name;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                if (!name || typeof name !== "string") {
                                    return [2];
                                }
                                _c.label = 1;
                            case 1:
                                _c.trys.push([1, 3, , 4]);
                                return [4, updater({ name: name })];
                            case 2:
                                _c.sent();
                                setHasInputChanged(false);
                                return [3, 4];
                            case 3:
                                e_1 = _c.sent();
                                return [2, (0, kaleidoscope_1.createFormSubmitErrorObject)(e_1.response.data.error.message)];
                            case 4: return [2];
                        }
                    });
                }); } },
                react_1.default.createElement(kaleidoscope_1.FormContext.Consumer, null, function (ctx) { return (react_1.default.createElement("div", { style: { display: "grid", gap: 12 } },
                    (ctx === null || ctx === void 0 ? void 0 : ctx.submitError) && (react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: !!(ctx === null || ctx === void 0 ? void 0 : ctx.submitError), timeout: kaleidoscope_1.AnimationDuration.Short, "aria-live": "polite" }, function (_a) {
                        var expandableElementRef = _a.expandableElementRef;
                        return (react_1.default.createElement("div", { ref: expandableElementRef },
                            react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Error", alertType: kaleidoscope_1.AlertType.Error, "aria-live": "polite" }, ctx.submitError)));
                    })),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "s" },
                        react_1.default.createElement(kaleidoscope_1.FormTextInput, { initialValue: name, label: "Name", name: "name", onChange: function (val) {
                                setHasInputChanged(val !== name);
                            }, placeholder: "Please enter your name", validate: function (val) {
                                if (!val) {
                                    return "Name cannot be empty";
                                }
                                return;
                            } }),
                        react_1.default.createElement(kaleidoscope_1.FormSubmitButton, { disabled: !hasInputChanged }, "Update Name")))); })))));
};
var EmailSection = function (_a) {
    var _b;
    var email = _a.email, updater = _a.updater;
    var _c = __read((0, react_1.useState)(false), 2), hasInputChanged = _c[0], setHasInputChanged = _c[1];
    var data = (0, useActiveApps_1.useActiveApps)();
    var hasSpringboard = data === null || data === void 0 ? void 0 : data.data.Springboard;
    var modal = (0, inversify_react_1.useInjection)("modal");
    return (react_1.default.createElement(kaleidoscope_1.Card, { className: profile_css_1.cardStyle },
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "m", padding: "l" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center" },
                react_1.default.createElement("div", { style: (0, dynamic_1.assignInlineVars)((_b = {},
                        _b[profile_css_1.vars.iconBackgroundColor] = hasSpringboard ? tokens_1.palette.yellow100 : tokens_1.palette.green100,
                        _b[profile_css_1.vars.iconColor] = hasSpringboard ? tokens_1.palette.yellow400 : tokens_1.tokens.color.iconSuccess,
                        _b)), className: profile_css_1.iconWrapper },
                    react_1.default.createElement(icons_1.Mail, { size: icons_1.IconSize.Medium, className: profile_css_1.iconStyle })),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", align: "start", padding: "s" },
                    react_1.default.createElement(kaleidoscope_1.Label, { size: "l" }, "Email"),
                    react_1.default.createElement(kaleidoscope_1.Label, { size: "s", secondary: true }, hasSpringboard ? "Contact support to edit your email" : "Update your email address here"))),
            hasSpringboard && (react_1.default.createElement(kaleidoscope_1.Text, { size: "s" },
                "Changing your email now affects both your Qwilr and Springboard profiles. We'll support changing these in one place in the app soon, but for now please",
                " ",
                react_1.default.createElement(kaleidoscope_1.Link, { href: "mailto:help@qwilr.com?subject=Qwilr and Springboard profile change request" }, "contact support"),
                " ",
                "to make any changes.")),
            react_1.default.createElement(kaleidoscope_1.FormComponent, { onSubmit: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
                    var e_2;
                    var email = _b.email, password = _b.password;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                if (!email || typeof email !== "string") {
                                    return [2];
                                }
                                if (!password || typeof password !== "string") {
                                    return [2];
                                }
                                _c.label = 1;
                            case 1:
                                _c.trys.push([1, 4, , 5]);
                                return [4, updater({ email: email, password: password })];
                            case 2:
                                _c.sent();
                                setHasInputChanged(false);
                                return [4, modal.showAcknowledgeModal({
                                        title: "Email Changed",
                                        body: react_1.default.createElement(kaleidoscope_1.Text, null, "Your email has been changed and you will now need to log in again"),
                                    })];
                            case 3:
                                _c.sent();
                                window.location.reload();
                                return [3, 5];
                            case 4:
                                e_2 = _c.sent();
                                return [2, (0, kaleidoscope_1.createFormSubmitErrorObject)(e_2.response.data.error.message)];
                            case 5: return [2];
                        }
                    });
                }); } },
                react_1.default.createElement(kaleidoscope_1.FormContext.Consumer, null, function (ctx) { return (react_1.default.createElement("div", { style: { display: "grid", gap: 12 } },
                    (ctx === null || ctx === void 0 ? void 0 : ctx.submitError) && (react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: !!(ctx === null || ctx === void 0 ? void 0 : ctx.submitError), timeout: kaleidoscope_1.AnimationDuration.Short, "aria-live": "polite" }, function (_a) {
                        var expandableElementRef = _a.expandableElementRef;
                        return (react_1.default.createElement("div", { ref: expandableElementRef },
                            react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Error", alertType: kaleidoscope_1.AlertType.Error, "aria-live": "polite" }, ctx.submitError)));
                    })),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "s" },
                        react_1.default.createElement(kaleidoscope_1.FormTextInput, { disabled: hasSpringboard, initialValue: email, label: "Email", name: "email", onChange: function (val) {
                                setHasInputChanged(val !== email);
                            }, placeholder: "Your updated email", type: kaleidoscope_1.TextInputType.Email, validate: function (val) {
                                if (!val) {
                                    return "Field required";
                                }
                                return;
                            } }),
                        react_1.default.createElement(kaleidoscope_1.FormTextInput, { type: kaleidoscope_1.TextInputType.Password, label: "Current password", name: "password", validate: function (val) {
                                if (!val) {
                                    return "Field required";
                                }
                                return;
                            } }),
                        react_1.default.createElement(kaleidoscope_1.FormSubmitButton, { disabled: !hasInputChanged }, "Update Email")))); })))));
};
var PasswordSection = function (_a) {
    var _b;
    var updater = _a.updater;
    var _c = __read((0, react_1.useState)(false), 2), hasCurrent = _c[0], setHasCurrent = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), hasNew = _d[0], setHasNew = _d[1];
    var _e = __read((0, react_1.useState)(false), 2), hasConfirm = _e[0], setHasConfirm = _e[1];
    return (react_1.default.createElement(kaleidoscope_1.Card, { className: profile_css_1.cardStyle },
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "m", padding: "l" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center" },
                react_1.default.createElement("div", { style: (0, dynamic_1.assignInlineVars)((_b = {},
                        _b[profile_css_1.vars.iconBackgroundColor] = tokens_1.palette.orange100,
                        _b[profile_css_1.vars.iconColor] = tokens_1.tokens.color.iconCaution,
                        _b)), className: profile_css_1.iconWrapper },
                    react_1.default.createElement(icons_1.OptionsHorizontal, { size: icons_1.IconSize.Medium, className: profile_css_1.iconStyle })),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", align: "start", padding: "s" },
                    react_1.default.createElement(kaleidoscope_1.Label, { size: "l" }, "Password"),
                    react_1.default.createElement(kaleidoscope_1.Label, { size: "s", secondary: true }, "Set a new password here"))),
            react_1.default.createElement(kaleidoscope_1.FormComponent, { onSubmit: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
                    var hasPassword, hasNewPassword, hasConfirmPassword, e_3;
                    var currentpassword = _b.currentpassword, newpassword = _b.newpassword, confirmpassword = _b.confirmpassword;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                hasPassword = currentpassword && typeof currentpassword === "string";
                                hasNewPassword = newpassword && typeof newpassword === "string";
                                hasConfirmPassword = confirmpassword && typeof confirmpassword === "string";
                                if (!hasPassword || !hasNewPassword || !hasConfirmPassword) {
                                    return [2];
                                }
                                if (newpassword !== confirmpassword) {
                                    return [2, (0, kaleidoscope_1.createFormSubmitErrorObject)("New Password mismatch", {
                                            confirmpassword: "Value does not match 'New Password' value",
                                        })];
                                }
                                _c.label = 1;
                            case 1:
                                _c.trys.push([1, 3, , 4]);
                                return [4, updater({
                                        password: currentpassword,
                                        newPassword: newpassword,
                                        confirmPassword: confirmpassword,
                                    })];
                            case 2:
                                _c.sent();
                                setHasCurrent(false);
                                setHasNew(false);
                                setHasConfirm(false);
                                return [3, 4];
                            case 3:
                                e_3 = _c.sent();
                                return [2, (0, kaleidoscope_1.createFormSubmitErrorObject)(e_3.response.data.error.message)];
                            case 4: return [2];
                        }
                    });
                }); } },
                react_1.default.createElement(kaleidoscope_1.FormContext.Consumer, null, function (ctx) { return (react_1.default.createElement("div", { style: { display: "grid", gap: 12 } },
                    (ctx === null || ctx === void 0 ? void 0 : ctx.submitError) && (react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: !!(ctx === null || ctx === void 0 ? void 0 : ctx.submitError), timeout: kaleidoscope_1.AnimationDuration.Short, "aria-live": "polite" }, function (_a) {
                        var expandableElementRef = _a.expandableElementRef;
                        return (react_1.default.createElement("div", { ref: expandableElementRef },
                            react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Error", alertType: kaleidoscope_1.AlertType.Error, "aria-live": "polite" }, ctx.submitError)));
                    })),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "s" },
                        react_1.default.createElement(kaleidoscope_1.FormTextInput, { type: kaleidoscope_1.TextInputType.Password, label: "Current password", name: "currentpassword", onChange: function (val) {
                                setHasCurrent(val.length > 0);
                            }, validate: function (val) {
                                if (!val) {
                                    return "Field required";
                                }
                                return;
                            } }),
                        react_1.default.createElement(kaleidoscope_1.FormTextInput, { type: kaleidoscope_1.TextInputType.Password, label: "New password", name: "newpassword", onChange: function (val) {
                                setHasNew(val.length > 0);
                            }, validate: function (val) {
                                if (!val) {
                                    return "Field required";
                                }
                                return;
                            } }),
                        react_1.default.createElement(kaleidoscope_1.FormTextInput, { type: kaleidoscope_1.TextInputType.Password, label: "Confirm new password", name: "confirmpassword", onChange: function (val) {
                                setHasConfirm(val.length > 0);
                            }, validate: function (val) {
                                if (!val) {
                                    return "Field required";
                                }
                                return;
                            } }),
                        react_1.default.createElement(kaleidoscope_1.FormSubmitButton, { disabled: !hasNew || !hasCurrent || !hasConfirm }, "Update Password")))); })))));
};
