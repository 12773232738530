"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVideoThumbnail = exports.getVideoPlatform = exports.getCanonicalVideoUrl = void 0;
var matchers = {
    youtube: [
        /youtu\.be\/([\w-]+)/,
        /youtube\.com\/v\/([\w-]+)/,
        /youtube\.com\/embed\/([\w-]+)/,
        /youtube\.com\/.*v=([\w-]+)/,
    ],
    vimeo: [/vimeo\.com\/(?:.*\/)*(\d+(?::\w+)?)/, /player\.vimeo\.com\/video\/(\d+(?::\w+)?)/],
};
var getVideoId = function (input) {
    var e_1, _a;
    try {
        for (var _b = __values(Object.entries(matchers)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), platform = _d[0], platformMatchers = _d[1];
            var match = platformMatchers.map(function (matcher) { return input.match(matcher); }).find(function (matches) { return !!matches; });
            if (match && match[1]) {
                return { platform: platform, id: match[1] };
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    throw new Error("Unable to get video ID for ".concat(input));
};
var getCanonicalVideoUrl = function (input) {
    var _a = getVideoId(input), platform = _a.platform, id = _a.id;
    if (platform === "youtube") {
        return "https://www.youtube.com/watch?v=".concat(id);
    }
    if (platform === "vimeo") {
        return "https://vimeo.com/".concat(id);
    }
    throw new Error("Unable to get canonical video URL for ".concat(input));
};
exports.getCanonicalVideoUrl = getCanonicalVideoUrl;
var getVideoPlatform = function (input) {
    var platform = getVideoId(input).platform;
    return platform;
};
exports.getVideoPlatform = getVideoPlatform;
var getVideoThumbnail = function (input) { return __awaiter(void 0, void 0, Promise, function () {
    var _a, platform, id, canonicalUrl, response, thumbnailUrl;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = getVideoId(input), platform = _a.platform, id = _a.id;
                if (platform === "youtube") {
                    return [2, "https://img.youtube.com/vi/".concat(id, "/maxresdefault.jpg")];
                }
                if (!(platform === "vimeo")) return [3, 3];
                canonicalUrl = (0, exports.getCanonicalVideoUrl)(input);
                return [4, fetch("https://vimeo.com/api/oembed.json?url=".concat(encodeURIComponent(canonicalUrl), "&width=1920&height=1080"))];
            case 1:
                response = _b.sent();
                if (response.status !== 200) {
                    throw new Error("Unable to retrieve thumbnail");
                }
                return [4, response.json()];
            case 2:
                thumbnailUrl = (_b.sent()).thumbnail_url;
                return [2, thumbnailUrl];
            case 3: throw new Error("Unable to get video thumbnail for ".concat(input));
        }
    });
}); };
exports.getVideoThumbnail = getVideoThumbnail;
